import { Injectable } from '@angular/core';
import axios, { AxiosInstance } from 'axios';
import { Observable, from } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  axiosInstance: AxiosInstance;
  userData: any
  constructor() {
    const userdata = sessionStorage.getItem('userData')
    if (userdata) {
      this.userData = JSON.parse(userdata)
    }

    this.axiosInstance = axios.create({
      baseURL: environment.BaseUrl,
      headers: {
        'Content-Type': 'application/json',
        'app_platform': 'mvaahna',
        // 'user_id': (this.userData.id) ? this.userData.id.toString() : "0",
        // 'company_id': (this.userData.company_id) ? this.userData.company_id.toString() : "0",
        // 'country_id': (this.userData.country_id) ? this.userData.country_id.toString() : "0",
      }
    });
  }


  public getAllState(): Observable<any> {
    return from(this.axiosInstance.post('association/getAssociation').then(
      res => {
        return res.data
      }
    ))
  }

  public getAllDistrict(input: any): Observable<any> {
    return from(this.axiosInstance.post('fetchAllCity', input).then(
      res => {
        return res.data
      }
    ))
  }

  public addMconnectOwner(input: any): Observable<any> {
    return from(this.axiosInstance.post('addMconnectOwner', input).then(
      res => {
        console.log(res.data.data);
        if (res.data.data.id) {
          sessionStorage.setItem("user_id", res.data.data.id);
          sessionStorage.setItem('userData', JSON.stringify(res.data.data))
        }
        return res.data
      }
    ))
  }
}
