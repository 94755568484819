<div class="bg-light h-100">
  <div style="height: 225px;background: #3b4a7b;" class="d-flex flex-column align-items-center justify-content-center">
    <img [src]="userData.profile_picture" class="rounded-pill img-fluid" style="height: 125px;width: 125px;">
    <div class="mt-2">
      <p class="m-0 mb-1 fs-5 text-white text-center fw-bold">{{userData.name | base64Decode}}</p>
      <p class="m-0 text-white text-center">{{userData.phone}}</p>
    </div>
  </div>

  <mat-nav-list>
    <span *ngFor="let item of menu ; let i = index">
      <span *ngIf="item.children && item.children.length > 0">
        <mat-accordion>
          <mat-expansion-panel class="shadow-none bg-light" hideToggle="true" [expanded]="step===i" (opened)="step=i">
            <mat-expansion-panel-header class="p-0" style="padding: 0 16px !important;">
              <mat-panel-title>

                <div class="d-flex justify-content-between align-items-center">
                  <!-- <mat-icon class="warn me-2" [svgIcon]="item.iconName"></mat-icon> -->
                  <img class="default-img-container" [src]="item.iconName" alt="">
                  <span class="fs-6 fw-bold">{{item.displayName}}</span>
                </div>
              </mat-panel-title>
              <mat-icon aria-hidden="false" aria-label="keyboard_arrow_right" fontIcon="keyboard_arrow_right"
                *ngIf="step!=i"></mat-icon>
              <mat-icon aria-hidden="false" aria-label="keyboard_arrow_down" fontIcon="keyboard_arrow_down"
                *ngIf="step===i"></mat-icon>
            </mat-expansion-panel-header>
            <span *ngFor="let child of item.children;let lastItem = last">
              <mat-list-item [routerLink]="getRoute(child.route)" (click)="handleNavigation(child.route);">
                <div class="d-flex justify-content-between align-items-center">
                  <!-- <mat-icon class="me-2" [svgIcon]="child.iconName"></mat-icon> -->
                  <img class="default-img-container" [src]="child.iconName" alt="">

                  <span class="fs-6 fw-bold">{{child.displayName}}</span>
                </div>
              </mat-list-item>
              <mat-divider *ngIf="lastItem"></mat-divider>
            </span>
          </mat-expansion-panel>
        </mat-accordion>
      </span>
      <span *ngIf="!item.children || item.children.length === 0">
        <mat-list-item [routerLink]="getRoute(item.route)" (click)="handleNavigation(item.route);">

          <div class="d-flex justify-content-between align-items-center">
            <!-- <mat-icon class="me-2" [svgIcon]="item.iconName"></mat-icon> -->
            <img class="default-img-container" [src]="item.iconName" alt="">
            <span class="fs-6 fw-bold"> {{item.displayName}}</span>
          </div>
        </mat-list-item>
      </span>
    </span>
  </mat-nav-list>
</div>
