// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 // BaseUrl: "http://localhost:8055/mconnect/",
   BaseUrl: "https://apitest.isteer.co/mconnect/",
  // paypal: {
  //   url: "https://api-m.paypal.com",
  //   clientId: "AYwd64NbG1kK5v13yrddXlRrXdz46r3V3qeZfs6b1lDtr-EQBX4lwVIuH4h5mhWrwq71sdiBP8_ang5H",
  //   clientSecret: "ECVig6MHBfUSXMJapvagfYAc03YPjhqzbrYPVhtHTkwKdvJoAZN1whDxjmG1n1qJ4ngnY0N1vcx37oas"
  // }
  paypal: {
    url: "https://api-m.sandbox.paypal.com",
    clientId: "Ab--WA9sJecRLAvM7hE9YRLpwJi927UehqQYRRqt2abUqBqQtPeIcgKGSsG8Dav-cHxEULQzvOrbQz0U",
    clientSecret: "EIOkw7qARqZwI8AR1xNx8uA2j-b34kqP4vtKy9lrk7nyZMXo9edPb-yVDpMIKqwCoY4mGoUc0-ya20hy"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
