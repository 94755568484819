import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { from, map, Observable } from 'rxjs';
import axios, { AxiosInstance } from 'axios'

export interface verifyOtpInput {
  mobile_number: string
  otp: string
}

@Injectable({
  providedIn: 'root'
})
export class DeleteUserService {

  axiosInstance: AxiosInstance;
  loginData!: any;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: environment.BaseUrl,
      headers: {
        'Content-Type': 'application/json',
        'app_platform': 'gadicare'
      }
    });
  }

  public getOTP(input: any): Observable<any> {
    return from(this.axiosInstance.post('login', input).then(
      res => {
        return res.data
      }
    ))
  }

  public verify_otp(input: any): Observable<any> {
    return from(this.axiosInstance.post('verify_otp', input).then(
      res => {
        return res.data
      }
    ))
  }

  public changeStatus(input: any): Observable<any> {
    return from(this.axiosInstance.post('changeStatus', input).then(
      res => {
        return res.data
      }
    ))
  }

  public checkMechanic(input: any): Observable<any> {
    return from(this.axiosInstance.post('checkMechanic', input).then(
      res => {
        return res.data
      }
    ))
  }

  public deleteUserOTP(input: any): Observable<any> {
    return from(this.axiosInstance.post('deleteEmailOTP', input).then(
      res => {
        return res.data
      }
    ))
  }
}
