<div class="p-3 border-bottom border-white ms-2 me-3">
  <div class="row g-0">
    <div class="col-1"></div>
    <div class="col-10 d-flex align-items-center justify-content-center"> <label
        class="font-large fw-bold text-white text-center ">Add Service</label></div>
    <div class="col-1 d-flex align-items-center justify-content-center">
      <mat-icon class="text-white" (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
    </div>
  </div>
</div>

<div class="p-3 border-bottom border-white ms-2 me-3">
  <div class="col-10 d-flex align-items-center justify-content-start"> <label
      class="font-large fw-bold text-white text-center ">Select services you offer</label></div>
</div>

<div class="p-3 border-bottom border-white ms-2 me-3"
  *ngIf="userData.user_category=='two wheeler' ||userData.user_category=='both'">
  <div class="col-10 d-flex align-items-center justify-content-start"> <label
      class="font-medium fw-bold text-white text-center ">Two Wheeler Services</label></div>
  <ng-container *ngFor="let x of twoWheelerServices">
    <mat-checkbox class="text-white" [(ngModel)]="x.value" *ngIf="x.show">{{ x.name }}</mat-checkbox>
  </ng-container>
</div>

<div class="p-3 border-bottom border-white ms-2 me-3"
  *ngIf="userData.user_category=='four wheeler' ||userData.user_category=='both'">
  <div class="col-10 d-flex align-items-center justify-content-start"> <label
      class="font-medium fw-bold text-white text-center ">Four Wheeler Services</label></div>
  <ng-container *ngFor="let x of fourWheelerServices">
    <mat-checkbox class="text-white" [(ngModel)]="x.value" *ngIf="x.show">{{ x.name }}</mat-checkbox>
  </ng-container>
</div>

<div class="p-3 ms-2 me-3">
  <mat-checkbox class="text-white" [(ngModel)]="other">Other Services</mat-checkbox>
  <div class="d-flex justify-content-center align-items-center">
    <input type="text" name="" id="" class="form-control" [(ngModel)]="otherService" *ngIf="other">
  </div>

  <div class="d-flex justify-content-center align-items-center ">
    <button class="btn text-white border-white ps-3 pe-3 rounded-2" (click)="addService()">Add</button>
  </div>
</div>
