import { Pipe, PipeTransform } from '@angular/core';
import Fuse from 'fuse.js';

@Pipe({
  name: 'globalFilter'
})
export class GlobalFilterPipe implements PipeTransform {
  transform(items: any[], keys: string[], searchTerm: string): any[] {
    if (!searchTerm || !items) {
      return items;
    }

    const options = {
      keys: keys,
      threshold: 0.3, // This will make the search more strict, only exact matches will pass
      minMatchCharLength: searchTerm.length, // This ensures that the search term length is considered
      shouldSort: false // Prevents re-sorting of the result
    };

    const fuse = new Fuse(items, options);
    const filteredData = fuse.search(searchTerm).map(x => x.item);

    return filteredData;
  }
}
