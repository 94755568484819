<div>
  <div class="row bg-light p-4 gap-2 g-0" id="fixed-top-bar">
    <div class="col d-none d-md-flex">
      <img src="assets\mechanic-app\image\shop.svg" alt="">
    </div>
    <div class="col-10 d-flex align-items-center justify-content-center">
      <p class="m-0 fs-6 fw-bold text-center" style="color:var(--primary-app-top-bar)">
        {{userData.shop_name|base64Decode}}</p>
    </div>
    <div class="col d-flex align-items-center justify-content-end">
      <mat-icon class="mat-18" (click)="close()">close</mat-icon>
    </div>
  </div>
  <div id="scrollableArea">
    <form [formGroup]="userForm" (ngSubmit)="addData()"
      class="ps-2 pe-2 mt-3  d-block  flex-column justify-content-center align-items-center flex-wrap">

      <div class="input-group mb-3">
        <span class="input-group-text" id="basic-addon1">
          <img src="assets\mechanic-app\image\profile.svg" alt="" style="height: 25px;width: 25px;">
        </span>
        <input type="text" class="form-control" placeholder="Name" aria-label="Name" aria-describedby="basic-addon1"
          formControlName="name">
      </div>
      <div>
        <mat-error *ngIf="userForm.get('name')?.hasError('required') && userForm.get('name')?.touched">This
          field is required</mat-error>
        <mat-error *ngIf="userForm.get('name')?.hasError('pattern')">This field is Invalid</mat-error>
        <mat-error *ngIf="userForm.get('name')?.hasError('maxLength')">This field cannot exceed 18
          characters</mat-error>
      </div>

      <div class="input-group mb-3">
        <span class="input-group-text" id="basic-addon1">
          <img src="assets\mechanic-app\image\phone.svg" alt="" style="height: 25px;width: 25px;">
        </span>
        <span class="input-group-text">+91</span>
        <input type="text" class="form-control" placeholder="Mobile Number" aria-label="Username"
          aria-describedby="basic-addon1" formControlName="contact_number">
      </div>
      <div>
        <mat-error
          *ngIf="userForm.get('contact_number')?.hasError('required') && userForm.get('contact_number')?.touched">This
          field is required</mat-error>
        <mat-error *ngIf="userForm.get('contact_number')?.hasError('pattern')">This field is Invalid</mat-error>
        <mat-error *ngIf="userForm.get('contact_number')?.hasError('maxLength')">This field cannot exceed 18
          characters</mat-error>
      </div>

      <div class="mb-3 mt-3">
        <div class="d-flex align-items-end gap-3">
          <label class="font-medium" style="color: var(--text-color-1); font-weight: 550;">Commission Percentage</label>
          <p class="m-0 font-medium text-decoration-underline"> {{this.userForm.value.commission_percentage}} %</p>
        </div>
        <div class="d-flex justify-content-start ms-5">
          <mat-slider aria-label="unit(s)" [max]="100" [min]="0" [step]="1" formControlName="commission_percentage"
            class="w-75"></mat-slider>
        </div>
      </div>

      <div class="d-flex flex-column gap-2">
        <section class="d-flex justify-content-between align-items-center ms-2 me-2">
          <p class="m-0 font-small" style="color:var(--primary-app-top-bar)">Add Items</p>
          <mat-slide-toggle formControlName="create_quotation"></mat-slide-toggle>
        </section>

        <section class="d-flex justify-content-between align-items-center ms-2 me-2">
          <p class="m-0 font-small" style="color:var(--primary-app-top-bar)">Share jobcard via link</p>
          <mat-slide-toggle formControlName="share_job_card_via_link"></mat-slide-toggle>
        </section>

        <section class="d-flex justify-content-between align-items-center ms-2 me-2">
          <p class="m-0 font-small" style="color:var(--primary-app-top-bar)">Update Job Card</p>
          <mat-slide-toggle formControlName="update_job_card"></mat-slide-toggle>
        </section>

        <section class="d-flex justify-content-between align-items-center ms-2 me-2">
          <p class="m-0 font-small" style="color:var(--primary-app-top-bar)">Show All Customer</p>
          <mat-slide-toggle formControlName="show_all_customers"></mat-slide-toggle>
        </section>

      </div>



      <div class="d-flex gap-3 flex-row flex-no-wrap p-md-3 p-2 justify-content-center align-items-center">
        <button mat-raised-button class="form-btn text-white" type="submit"
          style="background-color:var(--primary-app-top-bar) !important" [disabled]="!userForm.valid">Done</button>
      </div>
    </form>
  </div>
</div>
