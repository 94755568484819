import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DrawerService } from '../shared/services/drawer.service';
import { filter } from 'rxjs';

@Component({
  selector: 'app-sub-mechanic',
  templateUrl: './sub-mechanic.component.html',
  styleUrls: ['./sub-mechanic.component.scss']
})
export class SubMechanicComponent implements OnInit {

  constructor(private router: Router, private location: Location, private drawerService: DrawerService, private activatedRoute: ActivatedRoute) { }
  subMechanicId: number = 0
  routeLabel: string = '';
  nav_list: any


  ngOnInit(): void {
    const subId = sessionStorage.getItem('subMechanicId')
    if (subId) {
      this.subMechanicId = parseInt(subId)
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      let route = this.activatedRoute.firstChild;
      while (route && route.firstChild) {
        route = route.firstChild;
      }
      this.routeLabel = route?.snapshot.data['label'] || 'Default Label';

    });

    this.nav_list = [{ route: ['/sub-mechanic/dashboard', this.subMechanicId], icon: 'assets/mechanic-app/image/dashboard.svg', label: 'Dashboard' }, { route: ['/sub-mechanic/sub-mechanic-report', this.subMechanicId], icon: 'assets/mechanic-app/image/report.svg', label: 'Report' }, { route: ['/sub-mechanic/sub-mechanic-roles', this.subMechanicId], icon: 'assets/mechanic-app/image/serviceinfo.svg', label: 'Access Control' }, { route: ['/sub-mechanic/sub-mechanic-info', this.subMechanicId], icon: 'assets/mechanic-app/image/editprofile.svg', label: 'Edit' }]
  }

  goBack(): void {
    this.location.back();
  }

  goHome(): void {
    this.router.navigate(['/mvaahna-mechanic'])
  }

}
