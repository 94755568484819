<div class="p-3 border-bottom border-white ms-2 me-3">
  <div class="row g-0">
    <div class="col-1"></div>
    <div class="col-10 d-flex align-items-center justify-content-center">
      <label class="font-large fw-bold text-white text-center " *ngIf="formType=='tech_support'">Technical
        Support</label>
      <label class="font-large fw-bold text-white text-center " *ngIf="formType=='addSupplier'">Add Supplier</label>

    </div>
    <div class="col-1 d-flex align-items-center justify-content-center">
      <mat-icon class="text-white" (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
    </div>
  </div>
</div>

<div>
  <form [formGroup]="supportForm">
    <div class="p-3 d-flex flex-column gap-3" *ngIf="formType=='tech_support'">
      <input type="text" name="" id="" class="form-control" readonly formControlName="phone">
      <input type="text" name="" id="" class="form-control" readonly formControlName="app">
      <div class="form-group">
        <select class="form-control" id="dropdown" formControlName="request_type">
          <option value="" disabled selected hidden>Select Request Type*</option>
          <option *ngFor="let option of options" [value]="option">{{ option }}</option>
        </select>
      </div>
      <textarea name="" id="" placeholder="Remarks *" class="form-control" style="height: 100px;"></textarea>
    </div>

    <div class="p-3 d-flex flex-column" *ngIf="formType=='addSupplier'">
      <div class="input-group mb-3">
        <span class="input-group-text bg-white border-0" id="basic-addon1" style="height: 58px;">
          <img src="assets/mechanic-app/image/profile.svg" alt="">
        </span>
        <div class="form-floating">
          <input type="email" class="form-control" id="floatingInput" placeholder="Supplier Name"
            formControlName="name">
          <label for="floatingInput">Supplier Name</label>
        </div>
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text" id="basic-addon1" style="height: 58px;">
          <img src="assets/mechanic-app/image/phone.svg" alt="">
        </span>
        <div class="form-floating">
          <input type="email" class="form-control" id="floatingInput" placeholder="Supplier Name"
            formControlName="phone">
          <label for="floatingInput">Supplier Number</label>
        </div>
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text" id="basic-addon1" style="height: 58px;">
          <img src="assets/mechanic-app/image/sgt.svg" alt="">
        </span>
        <div class="form-floating">
          <input type="email" class="form-control" id="floatingInput" placeholder="GST"
            formControlName="gst">
          <label for="floatingInput">GST</label>
        </div>
      </div>
      <div class="input-group mb-2">
        <span class="input-group-text" id="basic-addon1" style="height: 58px;">
          <img src="assets/mechanic-app/image/mail.svg" alt="">
        </span>
        <div class="form-floating">
          <input type="email" class="form-control" id="floatingInput" placeholder="email"
            formControlName="email">
          <label for="floatingInput">Email</label>
        </div>
      </div>
    </div>

  </form>

  <div class="mt-2 d-flex justify-content-center align-items-center">
    <button class="btn text-white border-2 border-white">
      <span *ngIf="formType=='tech_support'">Submit</span>
      <span *ngIf="formType=='addSupplier'">Add</span>
    </button>
  </div>
</div>
