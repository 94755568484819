import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteUserComponent } from './delete-user/delete-user.component';
import { DeleteUserRoutingRoutingModule } from './delete-user-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CountDownComponent } from './count-down/count-down.component';



@NgModule({
  declarations: [
    DeleteUserComponent,
    CountDownComponent
  ],
  imports: [
    CommonModule,
    DeleteUserRoutingRoutingModule,
    SharedModule,
  ]
})
export class DeleteUserModule { }
