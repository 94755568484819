import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-payment-success-dialog',
  templateUrl: './payment-success-dialog.component.html',
  styleUrls: ['./payment-success-dialog.component.scss']
})
export class PaymentSuccessDialogComponent implements OnInit {

  transactionData: any
  constructor(public dialogRef1: MatDialogRef<PaymentSuccessDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  ngOnInit(): void {
    this.transactionData = this.data;
  }

  close() {
    this.dialogRef1.close();
  }

}
