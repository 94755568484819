<!-- <div class="h-100">
  <div class="row g-0">
    <div class="col p-0"></div>
    <div class="col-12 col-xxl-10 p-0 h-100">
      <app-header id="mainHeader"></app-header>
      <div id="routerMainDiv">
        <router-outlet></router-outlet>
        <app-footer></app-footer>
      </div>
    </div>
    <div class="col p-0"></div>
  </div>
</div> -->




<app-header id="mainHeader"></app-header>
<div id="routerMainDiv">
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div>
