import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CameraDialogComponent } from '../shared/dialog-box/camera-dialog/camera-dialog.component';
import { MapDialogComponent } from '../shared/dialog-box/map-dialog/map-dialog.component';
import { RegisterService } from '../shared/services/register.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-register-mechanic',
  templateUrl: './register-mechanic.component.html',
  styleUrls: ['./register-mechanic.component.scss']
})
export class RegisterMechanicComponent implements OnInit {

  profile_picture: string = ''
  registerForm: FormGroup = this.formBuilder.group({
    name: new FormControl('', [Validators.required]),
    company_name: new FormControl('', [Validators.required]),
    association_name: new FormControl(),
    sub_association_name: new FormControl(),
    association_id: new FormControl('', [Validators.required]),
    sub_association_id: new FormControl('', [Validators.required]),
    contact_number: new FormControl('', [Validators.required]),
    user_category: new FormControl('', [Validators.required]),
    longitude: new FormControl(0, []),
    latitude: new FormControl(0, []),
    address: new FormControl('', []),
    company_email: new FormControl('', []),
    gst_number: new FormControl('', []),
    alter_phone_number: new FormControl('', []),
    t_and_c: new FormControl('', [Validators.required]),
    referral_code: new FormControl('', []),
    profile_picture: new FormControl('', [Validators.required]),
    association: new FormControl('', []),
    association_flag: new FormControl(''),

  });

  state!: any[];
  district!: any[];


  user_category = [{ name: "Two Wheeler", value: "two wheeler" }, { name: "Four Wheeler", value: "four wheeler" }, { name: "Both", value: "both" }]

  constructor(private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private dialogbox: MatDialog, private regService: RegisterService, private toaster: ToastrService, private loader: NgxSpinnerService) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {


          this.registerForm.controls['latitude'].setValue(position.coords.latitude)
          this.registerForm.controls['longitude'].setValue(position.coords.longitude)
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.error('Geolocation: Permission denied');
              break;
            case error.POSITION_UNAVAILABLE:
              console.error('Geolocation: Position unavailable');
              break;
            case error.TIMEOUT:
              console.error('Geolocation: Request timeout');
              break;
            default:
              console.error('Geolocation: Unknown error');
              break;
          }
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.registerForm.controls['contact_number'].setValue(params.get('number'))
    });
    this.getState()
  }

  openCameraDialog() {
    let width;
    let height;

    if (window.innerWidth > 570) {
      width = '50%'
      height = '80%'
    } else {
      width = '80%'
      height = '90%'
    }

    this.dialogbox.open(CameraDialogComponent, {
      width: width,
      height: height,
      panelClass: 'zero-padding-margin-dialog',
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        this.profile_picture = result.selectedImage;
        this.registerForm.controls['profile_picture'].setValue(result.file_url)
      }
    })
  }

  getState() {

    this.regService.getAllState().subscribe((res: any) => {
      if (res.status == 1) {
        this.state = res.data
      }
    })

  }

  changeCity(event: Event) {
    const target = event.target as HTMLSelectElement | null;
    if (target) {
      const selectedStateId = target.value;
      let input = { state_id: selectedStateId }
      this.regService.getAllDistrict(input).subscribe((res: any) => {
        if (res.status == 1) {
          this.district = res.data
        }
      })
    }
  }

  setLocation() {
    let width;
    let height;

    if (window.innerWidth > 570) {
      width = '50%'
      height = '80%'
    } else {
      width = '80%'
      height = '90%'
    }

    this.dialogbox.open(MapDialogComponent, {
      width: width,
      height: height,
      disableClose: true,
      data: { lat: this.registerForm.get('latitude')?.value, lon: this.registerForm.get('longitude')?.value, distance: 10 }
    }).afterClosed().subscribe((result: any) => {
      if (result != undefined) {
        this.registerForm.controls['latitude'].setValue(result.lat)
        this.registerForm.controls['longitude'].setValue(result.lon)
        this.registerForm.controls['address'].setValue(result.address)
      }

    })
  }

  saveMechanic() {
    this.loader.show()
    let association = this.state.find((x: any) => x.id == this.registerForm.get('association_id')?.value);
    let sub_association = this.district.find((x: any) => x.id == this.registerForm.get('sub_association_id')?.value);

    let association_name = association ? association.association_name : '';
    let sub_association_name = sub_association ? sub_association.sub_division : '';

    this.registerForm.controls['association_name'].setValue(association_name);
    this.registerForm.controls['sub_association_name'].setValue(sub_association_name);
    // this.registerForm.controls['name'].setValue(Buffer.from(this.registerForm.get('name')?.value, 'utf8').toString('base64'))
    // this.registerForm.controls['company_name'].setValue(Buffer.from(this.registerForm.get('company_name')?.value, 'utf8').toString('base64'))
    // this.registerForm.controls['address'].setValue(Buffer.from(this.registerForm.get('address')?.value, 'utf8').toString('base64'))

    let input = this.registerForm.value
    input.name = Buffer.from(this.registerForm.get('name')?.value, 'utf8').toString('base64')
    input.company_name = Buffer.from(this.registerForm.get('company_name')?.value, 'utf8').toString('base64')
    input.address = Buffer.from(this.registerForm.get('address')?.value, 'utf8').toString('base64')

    this.regService.addMconnectOwner(this.registerForm.value).subscribe((res: any) => {
      if (res.status == 1) {
        this.toaster.success('', 'Registered Successfully..!', {
          timeOut: 3000
        })
        this.loader.hide()
        this.router.navigate(['/mvaahna-mechanic/all-vehicle'])
      }
    })
  }

  logOut() {
    sessionStorage.clear();
    this.router.navigate(['/mvaahna/home'])
  }

}
