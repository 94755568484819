import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  private headerDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  headerData$: Observable<any> = this.headerDataSubject.asObservable();

  private jcHeaderSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  jcheader$: Observable<any> = this.jcHeaderSubject.asObservable();

  tableResize!: () => void;
  constructor() { }

  setHeaderData(data: any): void {
    this.headerDataSubject.next(data);
  }

  setJcHeader(data: any): void {
    this.jcHeaderSubject.next(data);
  }
}
