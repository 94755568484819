import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { from, map, Observable } from 'rxjs';
import axios, { AxiosInstance } from 'axios'
declare var paypal: any;
@Injectable({
  providedIn: 'root'
})
export class PaypalService {

  axiosInstance: AxiosInstance;
  loginData!: any;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: environment.paypal.url,
      headers: {
        'app_platform': 'mvaahna',
        "Content-Type": "application/json"
      }
    });
  }

  public generateAccessToken(): Observable<any> {
    const auth = Buffer.from(`${environment.paypal.clientId}:${environment.paypal.clientSecret}`)
      .toString("base64");

    const additional_headers = {
      Authorization: `Basic ${auth}`,
      "Content-Type": "application/x-www-form-urlencoded"
    }

    const headers = { ...this.axiosInstance.defaults.headers.common, ...additional_headers };

    return from(this.axiosInstance.post('/v1/oauth2/token', "grant_type=client_credentials", { headers }).then(
      res => {
        return res.data
      }
    ))
  }



  public generateOrderId(input: any): Observable<any> {

    const additional_headers = {
      Authorization: `Bearer ${input.access_token}`,
      "Content-Type": "application/json"
    }

    var body = JSON.stringify({
      intent: "CAPTURE",
      purchase_units: [
        {
          amount: {
            currency_code: "USD",
            value: input.amount
          },
        },
      ],
    })

    const headers = { ...this.axiosInstance.defaults.headers.common, ...additional_headers };

    return from(this.axiosInstance.post('/v2/checkout/orders', body, { headers }).then(
      res => {
        console.log(res.data);

        return res.data
      }
    ))
  }

  public generatePayPalOrderID(input:any):Observable<any>{
    return from(this.axiosInstance.post('createPayPalOrder', input).then(
      res => {
        return res.data
      }
    ))
  }

  public capturePayPalOrder(input:any):Observable<any>{
    return from(this.axiosInstance.post('capturePayPalOrder', input).then(
      res => {
        return res.data
      }
    ))
  }

  // Render PayPal buttons with dynamic amount
  renderPaypalButtons(containerId: string, amount: string) {
    paypal.Buttons({
      style: {
        layout: 'vertical',
        color: 'gold', //orange, expected gold, blue, silver, black, white
        shape: 'pill', //rect,pill,
      },
      createOrder: (data: any, actions: any) => {
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: amount
            }
          }]
        });
      },
      onApprove: async (data: any, actions: any) => {
        const order = await actions.order.capture();
        console.log('Capture result:', order);
        return order
      },
      onError: (err: any) => {
        console.error('PayPal error:', err);
      }
    }).render(containerId);
  }





  // Render card fields with dynamic amount not in uses
  renderCardFields(amount: string) {
    const cardField = paypal.CardFields({
      style: {
        'input': {
          'font-size': '16px',
          'font-family': 'courier, monospace',
          // 'font-weight': 'lighter',
          // 'color': '#ccc',
        },
        '.invalid': {
          'color': 'purple',
        },
      },
      createOrder: (data: any, actions: any) => {
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: amount
            }
          }]
        });
      },
      onApprove: async (data: any, actions: any) => {
        const order = await actions.order.capture();
        console.log('Capture result:', order);
        // Redirect or show success message
      },
      onError: (err: any) => {
        console.error('PayPal error:', err);
        // Handle error
      }
    });
    console.log(cardField);

    if (cardField.isEligible()) {
      const nameField = cardField.NameField();
      nameField.render('#card-name-field-container');

      const numberField = cardField.NumberField();
      numberField.render('#card-number-field-container');

      const cvvField = cardField.CVVField();
      cvvField.render('#card-cvv-field-container');

      const expiryField = cardField.ExpiryField();
      expiryField.render('#card-expiry-field-container');

    }
  }


}
