import { Injectable } from '@angular/core';
import axios, { AxiosInstance } from 'axios';
import { environment } from 'src/environments/environment';
import { Observable, from, map } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomService {

  axiosInstance: AxiosInstance;
  axiosInstance1: AxiosInstance;

  private apiUrl = 'https://maps.googleapis.com/maps/api/geocode/json';

  constructor(private http: HttpClient) {
    this.axiosInstance = axios.create({
      baseURL: environment.BaseUrl,
      headers: {
        "Content-Type": "application/json"
      }
    });
    this.axiosInstance1 = axios.create({
      baseURL: environment.BaseUrl
    });
  }

  generateBoundaryString(): string {
    return '--------------------------' + Date.now();
  }

  public addDataToDB(url: string, data: any): Observable<any> {
    return from(this.axiosInstance.post(url, data).then((res: any) => {
      return res.data
    }))
  }

  public fileUpload(url: string, data: any): Observable<any> {
    return from(this.axiosInstance1.post(url, data).then((res: any) => {
      return res.data
    }))
  }


  public async fileUpload1(url: string, file: any, data: any): Promise<any> {
    console.log(file);

    const timeStamp = Date.now().toString();
    const boundary = this.generateBoundaryString();

    const formData = new FormData();
    formData.set('file', file);
    formData.set('image_type', 'vehicle');
    formData.set('vehicle_number', data.vehicle_number.toString());
    formData.set('jobcard_id', data.jobcard_id.toString());
    formData.set('mechanic_id', data.mechanic_id.toString());
    formData.set('track_id', data.track_id.toString());
    formData.forEach((value, key) => {
      console.log(key);
      console.log(value);
    });

    try {
      const response = await this.axiosInstance1.post('/addjobcardimage', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      console.error('File upload failed', error);
      throw error;
    }


  }
}
