import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { CheckoutComponent } from '../mobile-app-related/checkout/checkout.component';
import { PaymentSuccessDialogComponent } from '../mobile-app-related/dialog/payment-success-dialog/payment-success-dialog.component';
import { ToastrModule } from 'ngx-toastr';
import { LoginComponent } from './dialog-box/login/login.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Base64DecodePipe } from './pipe/base64-decode.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LeftsideNavComponent } from './components/leftside-nav/leftside-nav.component';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AddServiceComponent } from './dialog-box/add-service/add-service.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { TechSupportDialogComponent } from './dialog-box/tech-support-dialog/tech-support-dialog.component';
import { LanguageSelectComponent } from './dialog-box/language-select/language-select.component';
import { MatInputModule } from '@angular/material/input';
import { CircularProgressComponent } from './components/circular-progress/circular-progress.component';
import { MatTabsModule } from '@angular/material/tabs';
import { VehicleSelectDialogComponent } from './dialog-box/vehicle-select-dialog/vehicle-select-dialog.component';
import { CameraDialogComponent } from './dialog-box/camera-dialog/camera-dialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AddDialogComponent } from './dialog-box/add-dialog/add-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { MapDialogComponent } from './dialog-box/map-dialog/map-dialog.component';
import { MatSliderModule } from '@angular/material/slider';
import { EditServiceComponent } from './dialog-box/edit-service/edit-service.component';
import { AddTargetComponent } from './dialog-box/add-target/add-target.component';
import { AddSubMechanicComponent } from './dialog-box/add-sub-mechanic/add-sub-mechanic.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { GlobalFilterPipe } from './pipe/global-filter.pipe';
import { OtherVehicleComponent } from './dialog-box/other-vehicle/other-vehicle.component';
import { MatRippleModule } from '@angular/material/core';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    CheckoutComponent,
    PaymentSuccessDialogComponent,
    LoginComponent,
    Base64DecodePipe,
    SafeHtmlPipe,
    LeftsideNavComponent,
    AddServiceComponent,
    TechSupportDialogComponent,
    LanguageSelectComponent,
    CircularProgressComponent,
    VehicleSelectDialogComponent,
    CameraDialogComponent,
    AddDialogComponent,
    MapDialogComponent,
    EditServiceComponent,
    AddTargetComponent,
    AddSubMechanicComponent,
    GlobalFilterPipe,
    OtherVehicleComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    MatRadioModule,
    MatMenuModule,
    MatDialogModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    SlickCarouselModule,
    NgxSpinnerModule,
    MatExpansionModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatCardModule,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatSidenavModule,
    GoogleMapsModule,
    MatInputModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatSliderModule,
    MatBottomSheetModule,
    MatRippleModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    MatIconModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    MatRadioModule,
    MatMenuModule,
    MatDialogModule,
    Base64DecodePipe,
    NgxSpinnerModule,
    MatExpansionModule,
    SafeHtmlPipe,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatCardModule,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatSidenavModule,
    GoogleMapsModule,
    LeftsideNavComponent,
    MatInputModule,
    CircularProgressComponent,
    MatTabsModule,
    VehicleSelectDialogComponent,
    MatSlideToggleModule,
    MatSelectModule,
    AddDialogComponent,
    MatSliderModule,
    MatBottomSheetModule,
    GlobalFilterPipe,
    SlickCarouselModule,
    MatRippleModule
  ]
})
export class SharedModule { }
