import { Component, OnInit, Inject, ElementRef, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-map-dialog',
  templateUrl: './map-dialog.component.html',
  styleUrls: ['./map-dialog.component.scss']
})
export class MapDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<MapDialogComponent>, private el: ElementRef, private loader: NgxSpinnerService, private Renderer: Renderer2) {
    this.markerPositions.push({ lat: this.data.lat, lng: this.data.lon })
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: { lat: this.data.lat, lng: this.data.lon } }, (results, status) => {
      if (status === 'OK' && results && results.length > 0) {
        const address = results[0].formatted_address;
        // console.log('Address:', address);
        this.address = address
      } else {
        console.error('Geocoder failed due to:', status);
      }
    });

    this.Renderer.listen('window', 'resize', (event) => {
      this.setMapDimensions()
    })

  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.loader.show()
    setTimeout(() => {
      this.setMapDimensions();
      this.loader.hide();
    }, 1000);
  }


  max = 30;
  min = 0;
  step = 1;
  value = this.data.distance;
  selected_range!: number
  markerPositions: google.maps.LatLngLiteral[] = [];
  display: any;
  center: google.maps.LatLngLiteral = {
    lat: this.data.lat,
    lng: this.data.lon
  };
  zoom = 12;
  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    cursor: "pointer"
  };
  selected_lat: any = this.data.lat
  selected_lon: any = this.data.lon
  address!: string
  dialogWidth!: any;
  dialogHeight!: any;
  cur_addr: any = ''

  setMapDimensions() {
    const dialogWidth = this.dialogRef._containerInstance._config.width;
    const dialogHeight = this.dialogRef._containerInstance._config.height;
    const dialogWidthInPx = window.innerWidth * (parseInt(dialogWidth ?? '', 10) / 100);
    const dialogHeightInPx = window.innerHeight * (parseInt(dialogHeight ?? '', 10) / 100);
    const width = this.el.nativeElement.querySelector('#shopAddress').offsetWidth;
    const height = this.el.nativeElement.querySelector('#shopAddress').offsetHeight;
    // console.log('Width:', width);
    // console.log('Height:', height);
    // Calculate dimensions considering padding, borders, or margins
    const padding = 48 + (8 * 3); // Adjust this value according to your styling
    this.dialogWidth = dialogWidthInPx - padding;
    this.dialogHeight = dialogHeightInPx - padding - height;
  }

  addMarker(event: google.maps.MapMouseEvent) {
    this.markerPositions = [];
    if (event.latLng != null) this.markerPositions.push(event.latLng.toJSON());
    if (event.latLng != null) {
      this.selected_lat = event.latLng.toJSON().lat
      this.selected_lon = event.latLng.toJSON().lng
    }

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: this.markerPositions[0] }, (results, status) => {
      if (status === 'OK' && results && results.length > 0) {
        const address = results[0].formatted_address;
        // console.log('Address:', address);
        this.address = address
      } else {
        console.error('Geocoder failed due to:', status);
      }
    });
  }

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }

  changeRange(ev: any) {
    this.value = ev.value
  }

  selectAddress() {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: this.cur_addr }, (results, status) => {
      if (status === 'OK' && results && results.length > 0) {
        const location = results[0].geometry.location;
        const loc = { lat: location.lat(), lng: location.lng() };
        this.markerPositions = [];
        this.markerPositions.push(loc);
        this.selected_lat = location.lat();
        this.selected_lon = location.lng();
        this.center.lat = location.lat();
        this.center.lng = location.lng();
        this.address = results[0].formatted_address
        this.center = { lat: location.lat(), lng: location.lng() };
        this.zoom = 15;
        // console.log('Latitude:', location.lat());
        // console.log('Longitude:', location.lng());
      } else {
        console.error('Geocoder failed due to:', status);
      }
    });

  }

  closeDialog(flag: string): void {
    const dataToSend = {
      lat: this.selected_lat,
      lon: this.selected_lon,
      address: this.address,
      distance: this.value
    };
    if (flag == 'search') {
      this.dialogRef.close(dataToSend);
    } else {
      var input = {
        lat: this.selected_lat,
        lon: this.selected_lon,
        address: '',
        distance: this.value
      }
      this.dialogRef.close()
    }
  }

}
