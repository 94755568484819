import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrawerService {
  scrollHeight!: () => void;
  private toggleDrawerSubject = new Subject<void>();

  toggleDrawer$ = this.toggleDrawerSubject.asObservable();
  resize!: () => void;
  setToolbar!: () => void;
  customrId: any


  setCustomerId(id: any) {
    this.customrId = id
  }
  getCustomerId() {
    return this.customrId
  }

  toggleDrawer() {
    this.toggleDrawerSubject.next();
  }
}
