import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-tech-support-dialog',
  templateUrl: './tech-support-dialog.component.html',
  styleUrls: ['./tech-support-dialog.component.scss']
})
export class TechSupportDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<TechSupportDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) { }

  supportForm!: FormGroup
  formType = ''

  options = ['Login', 'Training', 'Feedback', 'Others'];

  ngOnInit(): void {
    this.formType = this.data.type

    if (this.data.type == 'tech_support') {
      this.supportForm = this.fb.group({
        phone: new FormControl('+918072232675', []),
        app: new FormControl('mVaaHna', []),
        request_type: new FormControl('', [Validators.required]),
        remarks: new FormControl('', [Validators.required])
      })
    } else {
      this.supportForm = this.fb.group({
        name: new FormControl('', [Validators.required]),
        phone: new FormControl('', [Validators.required]),
        gst: new FormControl('', [Validators.required]),
        email: new FormControl('', [])
      })
    }

  }
  closeDialog() {
    this.dialogRef.close();
  }
}
