import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomService } from '../../services/custom.service';

@Component({
  selector: 'app-camera-dialog',
  templateUrl: './camera-dialog.component.html',
  styleUrls: ['./camera-dialog.component.scss']
})
export class CameraDialogComponent implements OnInit, OnDestroy {
  @ViewChild('video') videoElement!: ElementRef;
  @ViewChild('canvas') canvasElement!: ElementRef;
  private stream!: MediaStream;
  showCamera: boolean = false;
  selectedImage: any;
  imgUrl: any;

  constructor(
    private dialogRef: MatDialogRef<CameraDialogComponent>,
    private el: ElementRef,
    private _customService: CustomService,
    private renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.renderer.listen('window', 'resize', (event) => {
      this.resizeDialog()
    });
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.resizeDialog()
  }

  ngOnDestroy(): void {
    this.stopCamera();
  }

  resizeDialog() {
    const dialogWidth = this.dialogRef._containerInstance._config.width;
    const dialogHeight = this.dialogRef._containerInstance._config.height;
    const dialogWidthInPx = window.innerWidth * (parseInt(dialogWidth ?? '', 10) / 100);
    const dialogHeightInPx = window.innerHeight * (parseInt(dialogHeight ?? '', 10) / 100);
    let buttonContainer = 0;

    if (this.el.nativeElement.querySelector('#dialogToolbar') && this.el.nativeElement.querySelector('#dialogContent')) {
      if (this.el.nativeElement.querySelector('#button')) {
        buttonContainer = this.el.nativeElement.querySelector('#button').offsetHeight;
      }
      this.el.nativeElement.querySelector('#dialogContent').style.height = dialogHeightInPx - this.el.nativeElement.querySelector('#dialogToolbar').offsetHeight + 'px';

      if (this.el.nativeElement.querySelector('#cameraView')) {
        this.el.nativeElement.querySelector('#cameraView').style.height = dialogHeightInPx - buttonContainer - this.el.nativeElement.querySelector('#dialogToolbar').offsetHeight + 'px';
      }
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedImage = e.target?.result;
        this.uploadImage(file);
      };
      reader.readAsDataURL(file);
    }
  }

  openCam() {
    this.showCamera = true;
    this.openCamera();
  }

  openCamera(): void {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          this.stream = stream;
          this.videoElement.nativeElement.srcObject = stream;
          this.videoElement.nativeElement.play();
        })
        .catch(err => {
          console.error('Error accessing camera: ', err);
        });
    } else {
      alert('Camera not supported in this browser.');
    }
  }

  stopCamera(): void {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      this.stream = null as any;
    }
  }

  captureImage(): void {
    const getCurrentTimestamp = (): Date => {
      return new Date();
    };
    const image_name = "captured_image" + getCurrentTimestamp() + ".jpg"
    const video = this.videoElement.nativeElement;
    const canvas = this.canvasElement.nativeElement;
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    this.selectedImage = canvas.toDataURL('image/png');
    canvas.toBlob((blob: any) => {
      if (blob) {
        const file = new File([blob], image_name, { type: "image/jpg" });
        this.uploadImage(file);
      }
    }, 'image/png');
  }

  uploadImage(file: File): void {
    const formData = new FormData();
    formData.append('file', file);

    if (this.data == null) {
      this._customService.fileUpload('addAnyFile', formData).subscribe(
        (response) => {
          this.imgUrl = response.file_url;
          this.closeDialog();
        },
        (error) => {
          console.error('File upload failed', error);
        }
      );
    } else {
      this._customService.fileUpload('addAnyFile', formData).subscribe(
        (response) => {
          this.imgUrl = response.file_url;
          this._customService.addDataToDB('addImageWithTrackId', { ...this.data, ...{ image_url: this.imgUrl } }).subscribe((res: any) => {
            if (res.status == 1) {
              this.closeDialog();
            }
          });
        },
        (error) => {
          console.error('File upload failed', error);
        }
      );
    }
  }

  closeDialog() {
    this.stopCamera();
    this.dialogRef.close({ selectedImage: this.selectedImage, file_url: this.imgUrl });
  }
}
