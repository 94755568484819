<div class="p-3 ms-2 me-3">
  <div class="row g-0">
    <div class="col-1">
      <!-- <img src="assets/mechanic-app/image/language.svg" alt=""> -->
      <mat-icon class="mat-18" svgIcon="language-icon"></mat-icon>
    </div>
    <div class="col-10 d-flex align-items-center justify-content-center"> <label
        class="font-large fw-bold text-white text-center ">Select Language</label></div>
    <div class="col-1 d-flex align-items-center justify-content-center">
      <mat-icon class="text-white" (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
    </div>
  </div>
</div>

<div class="bg-white overflow">

  <mat-nav-list>
    <ng-container *ngFor="let x of languageList">
      <mat-list-item (click)="sendMessage(x.value)">
        <div class="d-flex justify-content-center align-items-center w-100">
          <div class="d-flex align-items-center gap-2">
            <span class="custom-text-primary">{{x.name}}</span>
          </div>
        </div>
      </mat-list-item>
      <mat-divider class="ms-3 me-3"></mat-divider>
    </ng-container>
  </mat-nav-list>
</div>
