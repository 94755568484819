import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MechanicService } from '../../services/mechanic.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.scss']
})
export class AddServiceComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AddServiceComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private _mechanicService: MechanicService, private toaster: ToastrService) {

    let userData = sessionStorage.getItem('userData')
    if (userData) {
      this.userData = JSON.parse(userData)
    }
  }
  shopData: any = this.data.shop_data;
  userData: any
  other: any;
  otherService!: string
  twoWheelerServices: any[] = [
    { id: 0, show: true, value: false, service_type: "pickup", name: 'Pick up Service' },
    { id: 0, show: true, value: false, service_type: "drop", name: 'Drop off Service' },
    { id: 0, show: true, value: false, service_type: "towing", name: 'Towing Service' },
    { id: 0, show: true, value: false, service_type: "stand", name: 'Stand-by Service' },
    { id: 0, show: true, value: false, service_type: "emergency", name: 'Emergency Service' },
    { id: 0, show: true, value: false, service_type: "puncture", name: 'Puncture Service' }
  ];
  fourWheelerServices: any[] = [
    { id: 0, show: true, value: false, service_type: "fourpunctureservice", name: 'Four Wheeler Puncture Service' },
    { id: 0, show: true, value: false, service_type: "fourelectricservice", name: 'Four Wheeler Electric Service' },
    { id: 0, show: true, value: false, service_type: "fourengineservice", name: 'Four Wheeler Engine Service' },
    { id: 0, show: true, value: false, service_type: "fouracservice", name: 'Air Conditioning Service' },
    { id: 0, show: true, value: false, service_type: "fourtowingservice", name: 'Four Wheeler Towing Service' },
    { id: 0, show: true, value: false, service_type: "oilchange", name: 'Oil Change Service' },
    { id: 0, show: true, value: false, service_type: "tinkeringservice", name: 'Tinkering Service' },
    { id: 0, show: true, value: false, service_type: "transmissionservice", name: 'Transmission Service' },
    { id: 0, show: true, value: false, service_type: "tierallignmentservice", name: 'Tyre Alignment Service' }
  ];

  ngOnInit(): void {
    console.log(this.shopData);
    this.twoWheelerServices = this.twoWheelerServices.map((x: any) => {
      const serviceExists = this.shopData.services.filter((y: any) => y.service_type.toLowerCase().includes(x.service_type.toLowerCase()));

      if (serviceExists.length != 0) {
        x.value = true
        x.show = false
        x.id = serviceExists[0].id
        x.mechanic_id = this.userData.id
        x.service_name = Buffer.from(x.name, 'utf8').toString('base64')
        x.title = ""
        x.selected_services = "1"
        x.status = "A"
        x.time_slots = serviceExists[0].time_slots
        x.serviceable_radius = serviceExists[0].serviceable_radius
        x.price = serviceExists[0].price
        x.remarks = serviceExists[0].remarks
      } else {
        x.mechanic_id = this.userData.id
        x.service_name = Buffer.from(x.name, 'utf8').toString('base64')
        x.title = ""
        x.selected_services = "1"
        x.status = "A"
        x.time_slots = ""
        x.serviceable_radius = ""
        x.price = ""
        x.remarks = ""
      }
      return x;
    })

    this.fourWheelerServices = this.fourWheelerServices.map((x: any) => {
      const serviceExists = this.shopData.services.filter((y: any) => y.service_type.toLowerCase().includes(x.service_type.toLowerCase()));
      if (serviceExists.length != 0) {
        x.value = true
        x.show = false
        x.id = serviceExists[0].id
        x.mechanic_id = this.userData.id
        x.service_name = Buffer.from(x.name, 'utf8').toString('base64')
        x.title = ""
        x.selected_services = "1"
        x.status = "A"
        x.time_slots = serviceExists[0].time_slots
        x.serviceable_radius = serviceExists[0].serviceable_radius
        x.price = serviceExists[0].price
        x.remarks = serviceExists[0].remarks
      } else {
        x.mechanic_id = this.userData.id
        x.service_name = Buffer.from(x.name, 'utf8').toString('base64')
        x.title = ""
        x.selected_services = "1"
        x.status = "A"
        x.time_slots = ""
        x.serviceable_radius = ""
        x.price = ""
        x.remarks = ""
      }
      return x;
    })
  }

  addService() {
    const twoWhSer = this.twoWheelerServices.map((x: any) => { if (x.value == true) { return x } }).filter((z: any) => z != undefined)
    const foueWhSer = this.fourWheelerServices.map((x: any) => { if (x.value == true) { return x } }).filter((z: any) => z != undefined)



    let input = {
      mechanic_id: this.shopData.mechanic_id, shop_id: this.shopData.id, garage_picture: this.shopData.garage_picture, shoptimings: this.shopData.shoptimings
      , services: [...twoWhSer, ...foueWhSer]
    }

    if (this.other) {
      let otherobj = {
        value: true, title: Buffer.from(this.otherService, 'utf8').toString('base64'), mechanic_id: this.userData.id, id: 0, selected_services: "1", service_name: Buffer.from(this.otherService, 'utf8').toString('base64'), service_type: "other", status: "A", time_slots: "", serviceable_radius: "", price: "", remarks: "",
      }
      input.services.push(otherobj)
    }

    // console.log(input);return

    this._mechanicService.addService(input).subscribe((res: any) => {
      if (res.status == 1) {
        this.toaster.success('', 'Service added successfully ...!', {
          timeOut: 3000
        })
        this.closeDialog()
      } else {
        this.toaster.error('', 'Something went wrong..!', {
          timeOut: 3000
        })
      }
    })

  }

  closeDialog() {
    this.dialogRef.close();
  }


}
