import { Pipe, PipeTransform } from '@angular/core';
window.Buffer = window.Buffer || require('buffer').Buffer;
var isBase64 = require('is-base64');
@Pipe({
  name: 'base64Decode'
})
export class Base64DecodePipe implements PipeTransform {

  transform(value: string): string {

    if (value != null) {
      if (isBase64(value)) {
        return (Buffer.from(value, 'base64').toString('utf-8'))
      } else {
        return value
      }
    } else {
      return ''
    }
  }

}
