<div class="h-100">
  <div class="row g-0">
    <div class="col p-0"></div>
    <div class="col-12 col-xxl-10 p-0 h-100" id="mainDiv">

      <mat-toolbar class="tool-bar-color shadow">
        <div class="row g-0 w-100">
          <div class="col-4 d-flex align-items-center">
            <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
              (click)="goBack()">
              <mat-icon style="color:#FFF;">keyboard_arrow_left</mat-icon>
            </button>
          </div>
          <div class="col-4 d-flex align-items-center justify-content-center">
            <p class="m-0 text-white">{{routeLabel || 'Dashboard'}}</p>
          </div>
          <div class="col-4 d-flex align-items-center justify-content-end">
            <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
              (click)="goHome()">
              <mat-icon style="color: #FFF;">home</mat-icon>
            </button>
          </div>
        </div>
      </mat-toolbar>


      <div class="work-area" id="workArea">

        <div class="bg-light">
          <router-outlet></router-outlet>
        </div>


        <nav class="navbar navbar-expand-lg fixed-bottom"
          style="box-shadow: 0px -1px 7px 0px #0000001A;background: #FFFFFF;" id="bottomNavBar">
          <div class="col-12">
            <div class="row justify-content-evenly">
              <ng-container *ngFor="let x of nav_list">
                <div class="col-3 text-center">
                  <a class="nav-link" [routerLink]="x.route">
                    <img [src]="x.icon" [ngClass]="'fill-none'">
                    <p class="m-0 route-txt text-capitalize fw-bold" routerLinkActive="active">
                      {{x.label}}</p>
                  </a>
                </div>
              </ng-container>
            </div>
          </div>
        </nav>


      </div>

    </div>
    <div class="col p-0"></div>
  </div>
</div>
