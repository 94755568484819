import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MatTabGroup } from '@angular/material/tabs';
import { UserService } from '../../services/user.service';
import { DrawerService } from '../../services/drawer.service';

@Component({
  selector: 'app-vehicle-select-dialog',
  templateUrl: './vehicle-select-dialog.component.html',
  styleUrls: ['./vehicle-select-dialog.component.scss']
})
export class VehicleSelectDialogComponent implements OnInit {

  constructor(private userService: UserService, private drawerService: DrawerService, @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<VehicleSelectDialogComponent>, private domSanitizer: DomSanitizer, private el: ElementRef, private Renderer: Renderer2) {
    let userData = sessionStorage.getItem('userData')
    if (userData) {
      this.userData = JSON.parse(userData)
      console.log(this.userData);
    }
  }
  showtabs: boolean = true
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  vehicleCatrgory = 'two wheeler'
  brandDetails: any
  modelDetails: any
  fuelDetails: any
  selectedVehicle: any = { vehicle_brand: '', vehicle_model: '', fuel_type: '', vehicle_id: '', vehicleCatrgory: '' }
  userData: any;
  searchText: any

  ngOnInit(): void {
    // this.getBrands()

    // setTimeout(() => {
    //   this.drawerService.scrollHeight()
    // }, 500);
    this.changeCategory(this.userData.user_category)
  }

  ngAfterViewinit(): void {
    // console.log("ok");
  }


  resizeDialog() {
    const dialogWidth = this.dialogRef._containerInstance._config.width;
    const dialogHeight = this.dialogRef._containerInstance._config.height;
    const dialogWidthInPx = window.innerWidth * (parseInt(dialogWidth ?? '', 10) / 100);
    const dialogHeightInPx = window.innerHeight * (parseInt(dialogHeight ?? '', 10) / 100);
    let buttonContainer = 0

    setTimeout(() => {
      if (this.el.nativeElement.querySelector('#modelContainer')) {
        this.el.nativeElement.querySelector('#modelContainer').style.height = dialogHeightInPx - (49 + 65 + 38 + 77 + 16) + 'px'
        if (this.el.nativeElement.querySelector('#modelContainer1')) {
          this.el.nativeElement.querySelector('#modelContainer1').style.height = dialogHeightInPx - (49 + 65 + 38 + 77 + 16) + 'px'
        }
        if (this.el.nativeElement.querySelector('#modelContainer2')) {
          this.el.nativeElement.querySelector('#modelContainer2').style.height = dialogHeightInPx - (49 + 65 + 38 + 77 + 16) + 'px'
        }
      }

      console.log(dialogWidthInPx, dialogHeightInPx);
    }, 100);

  }

  changeCategory(type: string) {
    console.log("ok");
    this.vehicleCatrgory = (type == 'both') ? 'two wheeler' : type
    this.getBrands()
    if (type == 'both') {
      this.tabGroup.selectedIndex = 0;
    }
  }

  getBrands() {
    let input = { user_category: this.vehicleCatrgory }
    this.userService.getBrands(input).subscribe((res: any) => {
      this.brandDetails = res.data
      this.brandDetails.forEach((brand: any) => {
        if (brand.brand_image) {
          brand.brand_image = this.domSanitizer.bypassSecurityTrustUrl(brand.brand_image);
          this.resizeDialog()
        }
      });
    })
  }

  changeModel(brand: string) {
    if (brand.toLowerCase() == 'others') {
      this.selectedVehicle.vehicle_brand = brand
      this.selectedVehicle.vehicle_category = this.vehicleCatrgory
      this.dialogRef.close(this.selectedVehicle)

    } else {
      let input = { brand_name: brand, user_category: this.vehicleCatrgory }
      this.userService.getModels(input).subscribe((res: any) => {
        this.modelDetails = res.data
        this.modelDetails.forEach((model: any) => {
          if (model.brand_image) {
            model.model_image = this.domSanitizer.bypassSecurityTrustUrl(model.model_image);
          }
        });
        this.tabGroup.selectedIndex = 1;
        this.resizeDialog()

      })
    }
  }

  changeFuel(brand: string, model: String) {
    let input = { brand_name: brand, model_name: model, user_category: this.vehicleCatrgory }
    this.userService.getfuelType(input).subscribe((res: any) => {
      this.fuelDetails = res.data
      this.tabGroup.selectedIndex = 2;
      this.resizeDialog()

    })
  }

  setVehicle(column_data: any) {
    this.selectedVehicle.vehicle_brand = column_data.brand
    this.selectedVehicle.vehicle_model = column_data.model
    this.selectedVehicle.fuel_type = column_data.fuel_type
    this.selectedVehicle.vehicle_id = column_data.id
    this.selectedVehicle.vehicle_category = column_data.vehicle_category
    this.dialogRef.close(this.selectedVehicle);

  }

}
