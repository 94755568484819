<div class="bg-light">
  <mat-toolbar class="rounded-0" style="background-color: var(--invoice-bg);">
    <div class="row g-0 w-100">
      <div class="col-8 d-flex align-items-center justify-content-start">
        <p class="m-0 font-large" style="color: var(--primary-app-tool-bar);font-weight: 550;">LET'S GET STARTED</p>
      </div>
      <div class="col-4 d-flex align-items-center justify-content-end" (click)="logOut()">
        <img src="assets/mechanic-app/image/logout.svg" alt="" style="cursor: pointer;">
      </div>
    </div>
  </mat-toolbar>
  <div id="subRouteArea" style="height: calc(100vh - 120px);overflow: auto;">
    <div class="row g-0">
      <div class="col p-0"></div>
      <div class="col-12 col-xxl-8 p-0">
        <div class="ps-4 pe-4">

          <div class="d-flex flex-column justify-content-center align-items-center mt-4 position-relative"
            *ngIf="profile_picture!=''">
            <img src="{{ profile_picture }}" class="rounded-circle" style="height: 125px; width: 125px;"
              alt="Vehicle Image">
            <div class="camera-icon d-flex justify-content-center align-items-center" (click)="openCameraDialog()">
              <mat-icon class="text-white">photo_camera</mat-icon>
            </div>
          </div>

          <div class="d-flex flex-column justify-content-center align-items-center mt-4 position-relative"
            *ngIf="profile_picture==''">
            <div class="rounded-circle d-flex justify-content-center align-items-center bg-white"
              style="height: 125px; width: 125px;" alt="Vehicle Image">
              <span class="fw-bold text-center " style="color: var(  --primary-app-tool-bar);">Profile Picture</span>
            </div>
            <div class="camera-icon d-flex justify-content-center align-items-center" (click)="openCameraDialog()">
              <mat-icon class="text-white">photo_camera</mat-icon>
            </div>
          </div>
          <p class="text-danger text-end" *ngIf="profile_picture==''">&nbsp;*Profile picture is mandatory</p>

          <form [formGroup]="registerForm" class="mt-4">
            <div class="input-group mb-2">
              <span class="input-group-text bg-white" id="basic-addon1" style="height: 58px;">
                <img src="assets/mechanic-app/image/profile.svg" alt="">
              </span>
              <div class="form-floating">
                <input type="email" class="form-control border-start-0" id="floatingInput" formControlName="name"
                  placeholder="vehicle Number">
                <label for="floatingInput">Name<span class="align-top text-danger fs-6">&nbsp;*</span></label>
              </div>
            </div>

            <div class="input-group mb-2">
              <span class="input-group-text bg-white" id="basic-addon1" style="height: 58px;">
                <img src="assets/mechanic-app/image/shopname.svg" alt="">
              </span>
              <div class="form-floating">
                <input type="email" class="form-control border-start-0" id="floatingInput"
                  formControlName="company_name" placeholder="vehicle Number">
                <label for="floatingInput">Shop Name<span class="align-top text-danger fs-6">&nbsp;*</span></label>
              </div>
            </div>
            <div class="input-group mb-2">
              <span class="input-group-text bg-white" id="basic-addon1" style="height: 58px;">
                <img src="assets/mechanic-app/image/categouriesselection.svg" alt="">
              </span>
              <div class="form-floating">
                <select id="countrySelect" class="form-control border-start-0" formControlName="association_id"
                  aria-label="Select State" (change)="changeCity($event)">
                  <option *ngFor="let x of state" [value]="x.id">
                    {{ x.association_name }}
                  </option>
                </select>
                <label for="countrySelect">Select State<span class="align-top text-danger fs-6">&nbsp;*</span></label>
              </div>
            </div>

            <div class="input-group mb-2" *ngIf="registerForm.get('association_id')?.value!=''">
              <span class="input-group-text bg-white" id="basic-addon1" style="height: 58px;">
                <img src="assets/mechanic-app/image/categouriesselection.svg" alt="">
              </span>
              <div class="form-floating">
                <select id="countrySelect" class="form-control border-start-0" formControlName="sub_association_id"
                  aria-label="Select District">
                  <option *ngFor="let x of district" [value]="x.id">
                    {{ x.sub_division }}
                  </option>
                </select>
                <label for="countrySelect">Select District<span
                    class="align-top text-danger fs-6">&nbsp;*</span></label>
              </div>
            </div>


            <div class="input-group mb-2" (click)="setLocation()">
              <span class="input-group-text bg-white" id="basic-addon1" style="height: 58px;">
                <img src="assets/mechanic-app/image/locationpin.svg" alt="" style="height: 22px;width: 22px;">
              </span>
              <div class="form-floating">
                <input type="email" class="form-control border-start-0" id="floatingInput" formControlName="address"
                  placeholder="Address" readonly>
                <label for="floatingInput">Address<span class="align-top text-danger fs-6">&nbsp;*</span></label>
              </div>
            </div>

            <div class="input-group mb-2">
              <span class="input-group-text bg-white" id="basic-addon1" style="height: 58px;">
                <img src="assets/mechanic-app/image/call.svg" alt="">
              </span>
              <div class="form-floating">
                <input type="email" class="form-control border-start-0" id="floatingInput"
                  formControlName="contact_number" placeholder="Customer Number" readonly>
                <label for="floatingInput">phone Number</label>
              </div>
            </div>

            <div class="input-group mb-2">
              <span class="input-group-text bg-white" id="basic-addon1" style="height: 58px;">
                <img src="assets/mechanic-app/image/mail.svg" alt="">
              </span>
              <div class="form-floating">
                <input type="email" class="form-control border-start-0" id="floatingInput"
                  formControlName="company_email" placeholder="Calling Number">
                <label for="floatingInput">Company Email</label>
              </div>
            </div>

            <div class="input-group mb-2">
              <span class="input-group-text bg-white" id="basic-addon1" style="height: 58px;">
                <img src="assets/mechanic-app/image/sgt.svg" alt="" style="height: 22px;width: 22px;">
              </span>
              <div class="form-floating">
                <input type="email" class="form-control border-start-0" id="floatingInput" formControlName="gst_number"
                  placeholder="Address">
                <label for="floatingInput">GST No.</label>
              </div>
            </div>
            <div class="form-group mt-2">
              <label for="radioGroup" class="font-large fw-bold mb-2" style="color: var(--primary-app-tool-bar);">Select
                Category</label>
              <div id="radioGroup" class="ms-3">
                <mat-radio-group class="d-flex flex-column gap-2" formControlName="user_category">
                  <mat-radio-button *ngFor="let x of user_category" [value]="x.value" class="mb-2">
                    {{ x.name }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>

            <div class="mt-2">
              <p class="font-large fw-bold" style="color: var(--primary-app-tool-bar);">Invited By a firend ?</p>
            </div>

            <div class="input-group mb-2">
              <span class="input-group-text bg-white" id="basic-addon1" style="height: 58px;">
                <img src="assets/mechanic-app/image/referal.svg" alt="" style="height: 22px;width: 22px;">
              </span>
              <div class="form-floating">
                <input type="email" class="form-control border-start-0" id="floatingInput"
                  formControlName="referral_code" placeholder="Email">
                <label for="floatingInput">Enter Referral Code</label>
              </div>
            </div>

            <div class="d-flex flex-column gap-2 mt-2">
              <mat-checkbox class="example-margin" formControlName="association_flag">Are you an association
                member</mat-checkbox>
              <input type="text" class="form-control" name="" id="" formControlName="association" placeholder="Association Name"
                *ngIf="registerForm.value?.association_flag">
              <mat-checkbox class="example-margin" formControlName="t_and_c">I have read and agreed to the <span
                  class="fw-bold" style="color: var(--primary-app-tool-bar);">Terms and
                  Conditions</span></mat-checkbox>
            </div>

          </form>
        </div>
      </div>
      <div class="col p-0"></div>

    </div>
  </div>
  <div class="d-flex justify-content-center align-items-center shadow-lg p-2" id="SaveButton">
    <button class=" flex-fill btn text-white fw-bold fs-6 ms-5 me-5" style="background: var(--primar-btn-1);"
      (click)="saveMechanic()" [disabled]="registerForm.invalid">Continue</button>
  </div>
</div>
