import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private renderer: Renderer2, private el: ElementRef) {
    this.headerSticky()
    this.renderer.listen('window', 'resize', (event) => {
      this.headerSticky()
    })
  }

  ngOnInit(): void {
  }
  // activeRoute: any
  // getActivatedRoute() {
  //   this.router.events
  //     .pipe(filter(event => event instanceof NavigationEnd))
  //     .subscribe(() => {
  //       const path = this.router.url.split('/').pop();
  //       if (path?.includes("?")) {
  //         this.activeRoute = path.split('?')[0].toString();
  //       } else {
  //         this.activeRoute = path
  //       }

  //       if (path == 'changeStatus' || path == 'checkout') {
  //         this.activeRoute = false
  //       } else {
  //         this.activeRoute = true
  //       }
  //     });
  // }

  headerSticky() {
    setTimeout(() => {
      let height
      if (this.el.nativeElement.querySelector('#mainHeader') != null) {
        height = window.innerHeight - this.el.nativeElement.querySelector('#mainHeader').offsetHeight
      }

      if (this.el.nativeElement.querySelector('#routerMainDiv') != null) {
        this.el.nativeElement.querySelector('#routerMainDiv').style.height = height + 'px'
        this.el.nativeElement.querySelector('#routerMainDiv').style.overflowY = 'auto'
        this.el.nativeElement.querySelector('#routerMainDiv').style.overflowX = 'hidden'
      }
    }, 1);
  }
}
