import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-other-vehicle',
  templateUrl: './other-vehicle.component.html',
  styleUrls: ['./other-vehicle.component.scss']
})
export class OtherVehicleComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<OtherVehicleComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder) { }

  displayData: any = this.data;

  VehicleForm: FormGroup = this.formBuilder.group({
    vehicle_brand: new FormControl('', [Validators.required]),
    vehicle_model: new FormControl('', [Validators.required]),
    vehicle_category: new FormControl('', [Validators.required]),
    fuel_type: new FormControl('', [Validators.required]),
  });

  ngOnInit(): void {
    this.VehicleForm.controls['vehicle_category'].setValue(this.data.vehicle_category)
  }

  otherVehicle() {
    var vehicleData: any = {}
    vehicleData.vehicle_brand = this.VehicleForm.value.vehicle_brand
    vehicleData.vehicle_model = this.VehicleForm.value.vehicle_model
    vehicleData.fuel_type = this.VehicleForm.value.fuel_type
    vehicleData.vehicle_category = this.VehicleForm.value.vehicle_category
    this.dialogRef.close(vehicleData)
  }
  closeDialog() {
    this.dialogRef.close({});
  }

}
