import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MechanicService } from '../../services/mechanic.service';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-add-sub-mechanic',
  templateUrl: './add-sub-mechanic.component.html',
  styleUrls: ['./add-sub-mechanic.component.scss']
})
export class AddSubMechanicComponent implements OnInit {

  constructor(private fb: FormBuilder, private _mechanicService: MechanicService, private toastr: ToastrService, private bottomSheetRef: MatBottomSheetRef<AddSubMechanicComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) private data: any) {
    let userData = sessionStorage.getItem('userData')
    if (userData) {
      this.userData = JSON.parse(userData)
    }
  }

  userData: any

  userForm!: FormGroup

  ngOnInit(): void {
    this.userForm = this.fb.group({
      id: 0,
      name: new FormControl('', [Validators.required]),
      contact_number: new FormControl('', [Validators.required]),
      owner_id: new FormControl(this.userData.id.toString()),
      create_quotation: new FormControl(0),
      share_job_card_via_link: new FormControl(0),
      update_job_card: new FormControl(0),
      show_price: new FormControl(1),
      edit_price: new FormControl(1),
      show_all_customers: new FormControl(0),
      show_other_sub_mechanic_customers: new FormControl(0),
      commission_percentage: new FormControl(0),
      country_name: new FormControl(this.userData.country_name),
      country_id: new FormControl(this.userData.country_id)
    });

    this.setToggleValue('create_quotation');
    this.setToggleValue('share_job_card_via_link');
    this.setToggleValue('update_job_card');
    this.setToggleValue('show_all_customers');

  }

  setToggleValue(controlName: string) {
    this.userForm.get(controlName)?.valueChanges.subscribe(value => {
      if (typeof value === 'boolean') {
        this.userForm.get(controlName)?.setValue(value ? 1 : 0, { emitEvent: false });
      }
    });
  }

  addData() {

    if (this.userData.phone.includes(this.userForm.value.contact_number)) {
      this.toastr.warning('', "Sub Mechanic's number should be same as owner's number..!", {
        timeOut: 10000
      })
      return
    }
    // return console.log(this.userForm.value);
    this.userForm.value.name = Buffer.from(this.userForm.value.name, 'utf8').toString('base64')
    this.userForm.value.contact_number = '+91' + this.userForm.value.contact_number

    this._mechanicService.addSubMechanic(this.userForm.value).subscribe((res: any) => {
      if (res.status == 1) {
        this.toastr.success('', 'Sub Mechanic added successfully..!', {
          timeOut: 3000
        })
        this.bottomSheetRef.dismiss()
      } else {
        this.toastr.error('', 'Something went wrong..!', {
          timeOut: 3000
        })
      }
    })
  }

  close() {
    this.bottomSheetRef.dismiss()
  }

}
