<div>
  <div class="row tool-bar-color p-4 gap-2 g-0" id="fixed-top-bar">
    <div class="col d-none d-md-flex"></div>
    <div class="col d-flex align-items-center justify-content-center">
      <p class="m-0 fs-5 text-white fw-bold">Target for Month</p>
    </div>
    <div class="col d-flex align-items-center justify-content-end">
      <mat-icon class="mat-18 text-white" [mat-dialog-close]>close</mat-icon>
    </div>
  </div>
  <div id="scrollableArea">
    <form [formGroup]="userForm" (ngSubmit)="addData()"
      class="ps-2 pe-2 mt-3 d-flex d-md-block  flex-md-column justify-content-center align-items-center flex-wrap">

      <div class="input-group mb-3">
        <span class="input-group-text" id="basic-addon1">
          <img src="assets\mechanic-app\image\bikecar.svg" alt="">
        </span>
        <input type="text" class="form-control" placeholder="vehicle Intake" aria-label="Username"
          aria-describedby="basic-addon1" formControlName="vehicle_in_take">
      </div>
      <div>
        <mat-error
          *ngIf="userForm.get('vehicle_in_take')?.hasError('required') && userForm.get('vehicle_in_take')?.touched">This
          field is required</mat-error>
        <mat-error *ngIf="userForm.get('vehicle_in_take')?.hasError('pattern')">This field is Invalid</mat-error>
        <mat-error *ngIf="userForm.get('vehicle_in_take')?.hasError('maxLength')">This field cannot exceed 18
          characters</mat-error>
      </div>

      <div class="input-group mb-3">
        <span class="input-group-text" id="basic-addon1">
          <img src="assets\mechanic-app\image\sparesrevenue.svg" alt="">
        </span>
        <input type="text" class="form-control" placeholder="Spares Revenue" aria-label="Username"
          aria-describedby="basic-addon1" formControlName="spares_revenue">
      </div>
      <div>
        <mat-error
          *ngIf="userForm.get('spares_revenue')?.hasError('required') && userForm.get('spares_revenue')?.touched">This
          field is required</mat-error>
        <mat-error *ngIf="userForm.get('spares_revenue')?.hasError('pattern')">This field is Invalid</mat-error>
        <mat-error *ngIf="userForm.get('spares_revenue')?.hasError('maxLength')">This field cannot exceed 18
          characters</mat-error>
      </div>

      <div class="input-group mb-3">
        <span class="input-group-text" id="basic-addon1">
          <img src="assets\mechanic-app\image\labourrevenue.svg" alt="">
        </span>
        <input type="text" class="form-control" placeholder="labour Charges" aria-label="Username"
          aria-describedby="basic-addon1" formControlName="labour_revenue">
      </div>
      <div>
        <mat-error
          *ngIf="userForm.get('labour_revenue')?.hasError('required') && userForm.get('labour_revenue')?.touched">This
          field is required</mat-error>
        <mat-error *ngIf="userForm.get('labour_revenue')?.hasError('pattern')">This field is Invalid</mat-error>
        <mat-error *ngIf="userForm.get('labour_revenue')?.hasError('maxLength')">This field cannot exceed 18
          characters</mat-error>
      </div>




      <div class="d-flex gap-3 flex-row flex-no-wrap p-md-3 p-2 justify-content-center align-items-center">
        <button mat-raised-button class="form-btn" type="submit" [disabled]="!userForm.valid">Done</button>
      </div>
    </form>
  </div>
</div>
