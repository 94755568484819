<div>
  <div class="row tool-bar-color p-4 gap-2 g-0" id="fixed-top-bar">
    <div class="col d-none d-md-flex"></div>
    <div class="col d-flex align-items-center justify-content-center">
      <p class="m-0 fs-5 text-white fw-bold">{{data.title}}</p>
    </div>
    <div class="col d-flex align-items-center justify-content-end">
      <mat-icon class="mat-18 text-white" [mat-dialog-close]>close</mat-icon>
    </div>
  </div>
  <div id="scrollableArea">
    <form [formGroup]="userForm" (ngSubmit)="addData()"
      class="ps-2 pe-2 mt-3 d-flex d-md-block  flex-md-column justify-content-center align-items-center flex-wrap">

      <ng-container *ngFor="let field of jsonData.fields">

        <mat-form-field *ngIf="field.type === 'input'" appearance="outline" class="col-md-6 col-12 g-1 ps-2 pb-1 ">
          <mat-label>{{ field.label }}</mat-label>
          <input matInput [placeholder]="field.placeholder" autocomplete="off" type="text"
            [formControlName]="field.name">
          <mat-error *ngIf="userForm.get(field.name)?.hasError('required')">{{field.label}} is required</mat-error>
          <mat-error *ngIf="userForm.get(field.name)?.hasError('pattern')">{{field.label}} is Invalid</mat-error>
          <mat-error *ngIf="userForm.get(field.name)?.hasError('maxLength')">{{field.label}} cannot exceed 18
            characters</mat-error>

        </mat-form-field>

        <mat-form-field *ngIf="field.type === 'select'" appearance="outline" class="col-md-6 col-12 g-1 ps-2 pb-1">
          <mat-label for="user">{{field.label}}</mat-label>
          <mat-select [formControlName]="field.name" required id="user">
            <mat-option *ngFor="let x of field.options" [value]="x.value" #userType>
              {{x.label}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="userForm.get(field.name)?.hasError('required')">{{field.label}} is required</mat-error>
        </mat-form-field>


      </ng-container>
      <div
        class="d-flex align-items-md-end justify-content-md-end gap-3 flex-row flex-no-wrap p-md-3 p-2 justify-content-center align-items-center">
        <button mat-raised-button class="form-btn" type="submit" [disabled]="!userForm.valid">{{data.btn_name}}</button>
      </div>
    </form>
  </div>
</div>
