<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title" id="warningDialogLabel" style="color: #235B9D;">Transaction Details</h2>
    </div>
    <div class="modal-body mt-2 mb-2 fs-6">
      <div class="row">
        <div class="col-4 fw-bold text-start mt-2">
          <span>Amount</span>
        </div>
        <div class="col-8 text-start mt-2">
          <span>{{transactionData.Amount}}</span>
        </div>
        <div class="col-4 fw-bold text-start mt-2">
          <span>transaction ID</span>
        </div>
        <div class="col-8 text-start mt-2">
          <span>{{transactionData.merchantTranId}}</span>
        </div>
        <div class="col-4 fw-bold text-start mt-2">
          <span>Payment Status</span>
        </div>
        <div class="col-8 text-start mt-2">
          <span>{{transactionData.status}}</span>
        </div>
      </div>
    </div>
    <div class="modal-footer gap-2">
      <button type="button" class="btn btn-secondary" style="width:100px" [mat-dialog-close]>close</button>
    </div>
  </div>
</div>
