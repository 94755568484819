import { Component, ElementRef } from '@angular/core';
import { GlobalService } from '../shared/services/global.service';
import { BreakpointObserver } from '@angular/cdk/layout';
@Component({
  selector: 'app-mobile-app-related',
  templateUrl: './mobile-app-related.component.html',
})
export class MobileAppRelatedComponent {

  constructor(private globalService: GlobalService, private breakpointObserver: BreakpointObserver, private el: ElementRef) {
    this.globalService.tableResize = this.tableResize.bind(this)
  }

  tableResize() {
    setTimeout(() => {

      if (this.el.nativeElement.querySelector('#matCard') != null) {
        const matCardTotalHeight = this.el.nativeElement.querySelector('#matCard').offsetHeight;
        const matCardMargin = Math.round(parseFloat(window.getComputedStyle(this.el.nativeElement.querySelector('#matCard')).margin))
        const matCardPadding = Math.round(parseFloat(window.getComputedStyle(this.el.nativeElement.querySelector('#matCard')).padding))
        const paginator = (this.el.nativeElement.querySelector('#matPaginator') != null) ? this.el.nativeElement.querySelector('#matPaginator').offsetHeight : 0
        const filterHeader = Math.round(parseFloat(window.getComputedStyle(this.el.nativeElement.querySelector('#filterHeader')).height)) + Math.round(parseFloat(window.getComputedStyle(this.el.nativeElement.querySelector('#filterHeader')).marginBottom))
        let matTab;
        let matTab1;
        let TableContainer
        let filterHeight
        let breadCrumb
        if (this.el.nativeElement.querySelector('.mat-tab-header')) {
          matTab = Math.round(parseFloat(window.getComputedStyle(this.el.nativeElement.querySelector('.mat-tab-header')).height))
          matTab1 = Math.round(parseFloat(window.getComputedStyle(this.el.nativeElement.querySelector('.mat-tab-header')).height))
        }

        if (this.el.nativeElement.querySelector('#filterTab')) {
          filterHeight = this.el.nativeElement.querySelector('#filterTab').offsetHeight
        }


        if (this.el.nativeElement.querySelector('#breadCrumb')) {
          breadCrumb = this.el.nativeElement.querySelector('#breadCrumb').offsetHeight + 10
        }

        if (this.el.nativeElement.querySelector('#tableContainer')) {
          TableContainer = Math.round(parseFloat(window.getComputedStyle(this.el.nativeElement.querySelector('#tableContainer')).margin))
          this.el.nativeElement.querySelector('#tableContainer').style.height = (matCardTotalHeight - paginator - matCardMargin - matCardPadding - filterHeader - (breadCrumb ?? 0) - (matTab ?? 0) - (matTab1 ?? 0) - (TableContainer ?? 0) - (filterHeight ?? 0) - 1) + 'px'

          this.el.nativeElement.querySelector('#tableContainer').style.overflowY = 'auto'
        }
        if (this.el.nativeElement.querySelector('#tableContainer1')) {
          TableContainer = Math.round(parseFloat(window.getComputedStyle(this.el.nativeElement.querySelector('#tableContainer1')).margin))
          this.el.nativeElement.querySelector('#tableContainer1').style.height = (matCardTotalHeight - paginator - matCardMargin - matCardPadding - filterHeader - (breadCrumb ?? 0) - (matTab ?? 0) - (matTab1 ?? 0) - (TableContainer ?? 0) - (filterHeight ?? 0) - 1) + 'px'
          this.el.nativeElement.querySelector('#tableContainer1').style.overflowY = 'auto'
        }
        if (this.el.nativeElement.querySelector('#tableContainer2')) {
          TableContainer = Math.round(parseFloat(window.getComputedStyle(this.el.nativeElement.querySelector('#tableContainer2')).margin))
          this.el.nativeElement.querySelector('#tableContainer2').style.height = (matCardTotalHeight - paginator - matCardMargin - matCardPadding - filterHeader - (breadCrumb ?? 0) - (matTab ?? 0) - (matTab1 ?? 0) - (TableContainer ?? 0) - (filterHeight ?? 0) - 1) + 'px'
          this.el.nativeElement.querySelector('#tableContainer2').style.overflowY = 'auto'
        }
      }


    }, 1);
  }
}
