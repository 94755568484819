import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Inject, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map, shareReplay } from 'rxjs';
import { DrawerService } from '../../services/drawer.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TechSupportDialogComponent } from '../../dialog-box/tech-support-dialog/tech-support-dialog.component';
import { LanguageSelectComponent } from '../../dialog-box/language-select/language-select.component';
import { AuthService } from '../../services/auth.service';


interface MenuItem {
  displayName: string;
  iconName: string;
  route: string | (() => void);
  children?: MenuItem[];
}

@Component({
  selector: 'app-leftside-nav',
  templateUrl: './leftside-nav.component.html',
  styleUrls: ['./leftside-nav.component.scss']
})
export class LeftsideNavComponent implements OnInit {

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, private drawerService: DrawerService, public dialog: MatDialog, private loader: NgxSpinnerService, private toaster: ToastrService, private authService: AuthService) {


  }

  @Input() sideNav!: boolean
  showSideNav: boolean = false
  step: any;
  userData: any;

  menu: MenuItem[] = [{
    displayName: 'Profile',
    iconName: 'assets/mechanic-app/image/profile.svg',
    route: '#',
    children: [
      {
        displayName: 'Edit Profile',
        iconName: 'assets/mechanic-app/image/editprofile.svg',
        route: 'garage-profile',
      }, {
        displayName: 'Manage Shop',
        iconName: 'assets/mechanic-app/image/manageshop.svg',
        route: 'sub-mechanic',
      },
      {
        displayName: 'Service Calendar',
        iconName: 'assets/mechanic-app/image/servicecalender.svg',
        route: 'service-calendar',
      },
      {
        displayName: 'Service Reminder',
        iconName: 'assets/mechanic-app/image/servicereminder.svg',
        route: 'service-reminder',
      },
    ],
  },
  {
    displayName: 'Support',
    iconName: 'assets/mechanic-app/image/support.svg',
    route: '#',
    children: [
      {
        displayName: 'FAQ',
        iconName: 'assets/mechanic-app/image/notes.svg',
        route: () => this.openFAQ('faq'),
      }, {
        displayName: 'Techincal Support',
        iconName: 'assets/mechanic-app/image/technicalsupport.svg',
        route: () => this.openTechSupport('tech'),
      },
      {
        displayName: 'Chat Spport',
        iconName: 'assets/mechanic-app/image/chatsupport.svg',
        route: () => this.openChatSupport('chat'),
      }
    ],
  },
  {
    displayName: 'Settings',
    iconName: 'assets/mechanic-app/image/settings.svg',
    route: '#',
    children: [
      {
        displayName: 'Language',
        iconName: 'assets/mechanic-app/image/language.svg',
        route: () => this.openChatSupport('language'),
      }, {
        displayName: 'Deactivate Account',
        iconName: 'assets/mechanic-app/image/deacticateaccount.svg',
        route: () => this.changeStatus('deactivate'),
      }, {
        displayName: 'Delete Account',
        iconName: 'assets/mechanic-app/image/deleteaccount.svg',
        route: () => this.changeStatus('delete'),
      },
    ],
  },
  // {
  //   displayName: 'Orders',
  //   iconName: 'assets/mechanic-app/image/orders.svg',
  //   route: '#',
  //   children: [
  //     {
  //       displayName: 'Order Now',
  //       iconName: 'assets/mechanic-app/image/ordernow.svg',
  //       route: 'supplier-list',
  //     },
  //     {
  //       displayName: 'Track Order',
  //       iconName: 'assets/mechanic-app/image/trackorder.svg',
  //       route: 'supplier-order',
  //     }
  //   ],
  // },
  {
    displayName: 'Dashboard',
    iconName: 'assets/mechanic-app/image/dashboard.svg',
    route: 'dashboard'
  },
  {
    displayName: 'Payment Summary',
    iconName: 'assets/mechanic-app/image/paymentSummary.svg',
    route: 'payment-summary'
  },
  // {
  //   displayName: 'Video Library',
  //   iconName: 'assets/mechanic-app/image/videolibrary.svg',
  //   route: '#'
  // },
  {
    displayName: 'Logout',
    iconName: 'assets/mechanic-app/image/logout.svg',
    route: () => this.logout()
  }];

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),

      shareReplay()
    );

  ngOnInit(): void {
    const userData = sessionStorage.getItem('userData')
    if (userData) {
      this.userData = JSON.parse(userData)
    }

    if (this.userData.child_user != 0) {
      this.menu[0].children?.splice(1, 1)
    }
  }

  getRoute(route: any) {
    return typeof route === 'function' ? null : route;
  }

  // toggleSideNav();
  handleNavigation(route: any) {

    if (typeof route === 'function') {
      if (route.toString().split("'")[1] == 'delete' || route.toString().split("'")[1] == 'deactivate') {
        this.changeStatus(route.toString().split("'")[1]);
      } else if (route.toString().split("'")[1] == 'faq') {
        this.openFAQ('faq');
      } else if (route.toString().split("'")[1] == 'tech') {
        this.openTechSupport('tech')
      } else if (route.toString().split("'")[1] == 'chat') {
        this.openChatSupport('chat')
      } else if (route.toString().split("'")[1] == 'language') {
        this.openChatSupport('language')
      } else {
        this.logout();
      }
    } else {
      this.toggleSideNav()
    }
  }

  toggleSideNav() {
    this.drawerService.toggleDrawer();
  }

  openFAQ(flag: string) {
    window.open('http://localhost:4041/#/mvaahna/faq', '_blank');
  }

  openTechSupport(flag: string) {
    this.dialog.open(TechSupportDialogComponent, {
      width: '500px',
      height: '46%',
      panelClass: 'zero-padding-margin-dialog',
      data: { type: "tech_support" }
    }).afterClosed().subscribe((res: any) => {
      if (res) {

      }
    });
  }

  openChatSupport(flag: string) {
    this.dialog.open(LanguageSelectComponent, {
      width: '500px',
      height: '50%',
      panelClass: 'zero-padding-margin-dialog',
      data: { type: flag }
    }).afterClosed().subscribe((res: any) => {
      if (res) {

      }
    });
  }



  logout(): void {

    this.dialog.open(DeactivateUserDialog, {
      width: '500px',
      data: { title: `Logout`, content: `Are you sure you want to logout?` }
    }).afterClosed().subscribe((res: any) => {
      if (res) {
        sessionStorage.clear();
        this.router.navigate(['/mvaahna/home'])
      }
    });

  }

  changeStatus(type: string): void {
    console.log(type);
    if (type == 'deactivate') {
      this.dialog.open(DeactivateUserDialog, {
        width: '500px',
        data: { title: `Deactivate Account?`, content: `Are you sure you want to deactivate this account? Account can be reactivated during login` }
      }).afterClosed().subscribe((res: any) => {
        if (res) {
          this.loader.show();
          this.authService.changeStatus({ mobile_number: this.userData.phone, status: 'IN' }).subscribe((res: any) => {
            if (res.status == 1) {
              this.loader.hide();
              this.toaster.success('', 'Account Deactivated...!', {
                timeOut: 3000
              })
              sessionStorage.clear();
              this.router.navigate(['/mvaahna/home'])
            }
          })
        }
      });
    } else {
      // window.open(`#/mconnect/changeStatus?country_id=${this.userData.country_id}`)

      this.dialog.open(DeactivateUserDialog, {
        width: '500px',
        data: { title: `Delete Account?`, content: `Are you sure you want to delete this account? Your entire data will be permanently deleted` }
      }).afterClosed().subscribe((res: any) => {
        if (res) {
          this.loader.show();
          this.authService.changeStatus({ mobile_number: this.userData.phone, status: 'DEL' }).subscribe((res: any) => {
            if (res.status == 1) {
              this.loader.hide();
              this.toaster.success('', 'Account Deleted...!', {
                timeOut: 3000
              })
              sessionStorage.clear();
              this.router.navigate(['/mvaahna/home'])
            }
          })
        }
      });

    }

  }
}


@Component({
  selector: 'deactivate-user-dialog',
  template: `
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="warningDialogLabel" style="color: #235B9D;">{{displayData.title}}</h2>
      </div>
      <div class="modal-body mt-2 mb-2 fs-6">
        <p>{{displayData.content}}</p>
      </div>
      <div class="modal-footer gap-2">
        <button type="button" class="btn btn-secondary" (click)="closeDialog(false)">No</button>
        <button type="button" class="btn btn-primary" (click)="closeDialog(true)">Yes</button>
      </div>
    </div>
  </div>
  `,
})
export class DeactivateUserDialog {
  constructor(public dialogRef: MatDialogRef<DeactivateUserDialog>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  displayData: any = this.data;
  closeDialog(flag: boolean) {
    this.dialogRef.close(flag);
  }
}
