<div>
  <mat-toolbar class="tool-bar-color shadow">
    <div class="row g-0 w-100">
      <div class="col-4 d-flex align-items-center">
      </div>
      <div class="col-4 d-flex align-items-center justify-content-center">
        <p class="m-0 fs-6 text-white">Additional Services</p>

      </div>
      <div class="col-4 d-flex align-items-center justify-content-end">
        <mat-icon style="color: #FFF;cursor: pointer;" (click)="closeDialog()">close</mat-icon>
      </div>
    </div>
  </mat-toolbar>

  <div class="row g-0 p-2">
    <div class="col-2 d-flex justify-content-center align-items-center">
      <img [src]="getImage(serviceData.services[0]?.service_type)" alt="">
    </div>
    <div class="col-10 d-flex flex-column">
      <div>
        <p class="m-0 font-medium fw-bold" style="color: var(--primary-app-tool-bar);">
          {{getServiceNameTr(serviceData.services[0]?.service_type)}}</p>
      </div>
      <div>
        <p class="m-0 font-small">Specify details for the service provided by youe garage</p>
      </div>
    </div>
  </div>
  <div>
    <mat-card class="m-2 p-0">
      <div class="p-2" style="background-color: var(--invoice-bg);">
        <p class="m-0 font-medium fw-bold" style="color: var(--primary-app-tool-bar);">
          {{getServiceNameTr(serviceData.services[0]?.service_type)}}</p>
      </div>
      <mat-card-content>
        <div class="p-2">
          <section>
            <label class="font-small" style="color: var(--primary-app-top-bar);">Available Time slots</label>
            <div class="d-flex justify-content-evenly mt-2">
              <ng-container *ngFor="let x of timeSlots;let i=index">
                <mat-card class="shadow-none border border-2 rounded-2 pt-2 pb-2" style="cursor: pointer;"
                  (click)="selectSlot(i)" [ngClass]="(x.selected)? 'date-active':''">
                  <p class="m-0 date-txt">{{x.slot}}</p>
                </mat-card>
              </ng-container>
            </div>
          </section>
          <section class="mt-2">
            <label class="font-small" style="color: var(--primary-app-top-bar);">Minimum charges</label>
            <input type="text" class="form-control mt-1" [(ngModel)]="serviceData.services[0].price" name="" id="">
          </section>
          <section class="mt-2">
            <label class="font-small" style="color: var(--primary-app-top-bar);">Serviceable Radius (Km)</label>
            <input type="text" class="form-control mt-1" [(ngModel)]="serviceData.services[0].serviceable_radius"
              name="" id="">
          </section>
          <div class="input-group mt-2">
            <span class="input-group-text" id="basic-addon1">
              <img src="assets/mechanic-app/image/notes.svg" alt="">
            </span>
            <input type="text" class="form-control" placeholder="Additional Remarks"
              [(ngModel)]="serviceData.services[0].remarks" aria-label="Username" aria-describedby="basic-addon1">
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="d-flex mt-3 mb-3">
    <button class="btn text-white fw-bold fs-6 flex-fill ms-5 me-5" style="background: var(--primar-btn-1);"
      (click)="saveService()"> Save
      Changes</button>
  </div>
</div>
