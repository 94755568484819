import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MechanicService } from '../../services/mechanic.service';

@Component({
  selector: 'app-edit-service',
  templateUrl: './edit-service.component.html',
  styleUrls: ['./edit-service.component.scss']
})
export class EditServiceComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EditServiceComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private _mechanicService: MechanicService, private toaster: ToastrService) { }

  serviceData = this.data
  timeSlots = [
    { slot: '8 - 10 AM', selected: false },
    { slot: '10 - 12 PM', selected: false },
    { slot: '4 - 6 PM', selected: false },
    { slot: '6 - 8 PM', selected: false }
  ]

  ngOnInit(): void {
    this.serviceData.services[0].remarks = (this.serviceData.services[0].remarks != '') ? Buffer.from(this.serviceData.services[0].remarks, 'base64').toString('utf8') : this.serviceData.services[0].remarks
    let givenSlot = this.serviceData.services[0].time_slots.replace('[, ', '')
    givenSlot = givenSlot.replace(']', '')
    this.timeSlots = this.timeSlots.map(slot => {
      if (givenSlot.split(',').includes(slot.slot)) {
        return { ...slot, selected: true };
      }
      return slot;
    });
  }
  getServiceNameTr(serviceType: string): string {
    switch (serviceType.toLowerCase()) {
      case 'pickup':
        return 'Pick up Service';
      case 'drop':
        return 'Drop off Service';
      case 'puncture':
      case 'punctureservice':
        return 'Puncture Service';
      case 'towing':
      case 'towingservice':
        return 'Towing Service';
      case 'stand':
        return 'Stand-by Vehicle';
      case 'emergency':
        return 'Emergency Service';
      case 'fourpunctureservice':
        return 'Four Wheeler Puncture Service';
      case 'fourelectricservice':
        return 'Four Wheeler Electric Service';
      case 'fourengineservice':
        return 'Four Wheeler Engine Service';
      case 'fouracservice':
        return 'Air Conditioning Service';
      case 'fourtowingservice':
        return 'Four Wheeler Towing Service';
      case 'oilchange':
        return 'Oil Change Service';
      case 'tinkeringservice':
        return 'Tinkering Service';
      case 'transmissionservice':
        return 'Transmission Service';
      case 'tyrealignmentservice':
        return 'Tyre Alignment';
      default:
        return serviceType;
    }
  }

  getImage(serviceType: string): string {
    switch (serviceType.toLowerCase()) {
      case 'pickup':
        return 'assets/mechanic-app/image/pickupanddrop.svg';
      case 'drop':
        return 'assets/mechanic-app/image/pickupanddrop.svg';
      case 'puncture':
      case 'punctureservice':
        return 'src/assets/mechanic-app/image/puncher.svg';
      case 'towing':
      case 'towingservice':
        return 'assets/mechanic-app/image/towing.svg';
      case 'stand':
        return 'assets/mechanic-app/image/stantby.svg';
      case 'emergency':
        return 'assets/mechanic-app/image/emergency.svg';
      case 'fourpunctureservice':
        return 'Four Wheeler Puncture Service';
      case 'fourelectricservice':
        return 'Four Wheeler Electric Service';
      case 'fourengineservice':
        return 'Four Wheeler Engine Service';
      case 'fouracservice':
        return 'Air Conditioning Service';
      case 'fourtowingservice':
        return 'Four Wheeler Towing Service';
      case 'oilchange':
        return 'Oil Change Service';
      case 'tinkeringservice':
        return 'Tinkering Service';
      case 'transmissionservice':
        return 'Transmission Service';
      case 'tyrealignmentservice':
        return 'Tyre Alignment';
      default:
        return serviceType;
    }
  }

  selectSlot(index: any) {
    this.timeSlots[index].selected = (this.timeSlots[index].selected) ? false : true
  }

  saveService() {
    let timeSlots = this.timeSlots.map((x: any) => {
      if (x.selected == true) {
        return x.slot
      }
    }).filter((x: any) => x !== undefined);

    this.serviceData.services[0].remarks = Buffer.from(this.serviceData.services[0].remarks, 'utf8').toString('base64')
    this.serviceData.services[0].time_slots = timeSlots.toString()

    console.log(this.serviceData);


    this._mechanicService.addService(this.serviceData).subscribe((res: any) => {
      if (res.status == 1) {
        this.toaster.success('', 'Service edited successfully ...!', {
          timeOut: 3000
        })
        this.closeDialog()
      } else {
        this.toaster.error('', 'Something went wrong..!', {
          timeOut: 3000
        })
      }
    })

  }
  closeDialog() {
    this.dialogRef.close()
  }
}
