import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DeleteUserModule } from './delet-user-module/delete-user.module';;
import { MobileAppRelatedComponent } from './mobile-app-related/mobile-app-related.component';
import { PagesComponent } from './pages/pages.component';
import { SharedModule } from './shared/shared.module';
import { MechanicAppComponent } from './mechanic-app/mechanic-app.component';
import { SubMechanicComponent } from './sub-mechanic/sub-mechanic.component';



@NgModule({
  declarations: [
    AppComponent,
    MobileAppRelatedComponent,
    PagesComponent,
    MechanicAppComponent,
    SubMechanicComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DeleteUserModule,
    SharedModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule { }
