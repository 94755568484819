import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MechanicService } from '../../services/mechanic.service';

@Component({
  selector: 'app-add-target',
  templateUrl: './add-target.component.html',
  styleUrls: ['./add-target.component.scss']
})
export class AddTargetComponent implements OnInit {


  constructor(private dialogRef: MatDialogRef<AddTargetComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private _mechanicService: MechanicService, private toastr: ToastrService) {
    console.log(this.data);

  }

  userForm: FormGroup = this.fb.group({
    id: new FormControl(this.data.id),
    phone: new FormControl(this.data.phone),
    vehicle_in_take: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/)]),
    spares_revenue: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/)]),
    labour_revenue: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/)]),
    current_month: new FormControl(this.data.date)
  });


  ngOnInit(): void {
    if (this.data.tar_data.length > 0) {
      this.userForm.controls['vehicle_in_take'].setValue(this.data.tar_data[0].target_vehicle_in_take)
      this.userForm.controls['spares_revenue'].setValue(this.data.tar_data[0].target_spares_revenue)
      this.userForm.controls['labour_revenue'].setValue(this.data.tar_data[0].target_labour_revenue)
    }
  }



  addData() {
    // return console.log(this.userForm.value);

    this._mechanicService.addMonthlyTarget(this.userForm.value).subscribe((res: any) => {
      if (res.status == 1) {
        this.toastr.success('', 'Target Added Successfully..!', {
          timeOut: 3000
        })
        this.dialogRef.close()
      } else {
        this.toastr.error('', 'Something went wrong..!', {
          timeOut: 3000
        })
      }
    })
  }

}
