<div class="col-12 d-flex justify-content-center justify-content-md-start">
  <div class="col-12 d-flex flex-column align-items-center  align-items-md-start p-5"
    style="background: linear-gradient(180deg, #E8F6F7 8.85%, #AFE4E7 21.35%, #2C63A1 95.83%);">
    <!-- <img _ngcontent-vvw-c220="" src="/assets/mvaahna-logo.svg" style="width: 162px; height: 53px;"> -->
    <img _ngcontent-vvw-c220="" src="/assets/mvaahna-logo-1.svg" style="width: 200px; height: 60px;">

  </div>
</div>
<div class="d-flex flex-column gap-3 justify-content-center align-items-center bg-light" id="work_area">
  <!-- <mat-card class="shadow-none text-center"> -->
  <div class="fs-4 text-center">
    We are sorry to see you leave as a registered user
  </div>
  <ng-container *ngIf="steps=='step1'">
    <p class="fs-6 fw-bold">Please Select Country</p>
    <div class="dropdown">
      <div class="card m-2" style="cursor: default;" id="colorDropdownButton" data-bs-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <div class="card-body">
          <div class="d-flex justify-content-center align-items-center">
            <div>
              <span class="p-3">{{ selectedCountry || 'Select a country' }}</span>
            </div>
          </div>
        </div>
      </div>
      <ul class="dropdown-menu overflow-scroll m-3 bg-light" style="height: 150px;"
        aria-labelledby="colorDropdownButton">
        <li *ngFor="let x of countries" (click)="onSelect(x)" class="d-flex ms-2 mt-2" style="cursor: pointer;">
          <span class="flag-icon dropdown-item" ngClass="{{'flag-icon-' + x.country_code.toLowerCase()}}"></span>
          <span class="dropdown-item">{{ x.name }}</span>
        </li>
      </ul>
    </div>
  </ng-container>
  <div *ngIf="steps=='step2'">
    <div *ngIf="showNumberField" [formGroup]="userMobileForm">
      <label class="mb-2"> Please enter your mobile number</label>
      <div class="input-group">
        <span class="input-group-text">{{selectedCountryObj.code}}</span>
        <input type="text" class="form-control text-start" placeholder="Mobile Number" inputmode="tel" maxlength="15"
          aria-label="Search" aria-describedby="basic-addon2" formControlName="mobile_number">
      </div>
      <div class="mt-2 text-end">
        <span style="color:#245069;font-weight:500;text-decoration: underline;cursor: pointer;" (click)="goback()"
          *ngIf="country_id==undefined">Change Country</span>
      </div>
      <div
        *ngIf="userMobileForm.get('mobile_number')?.invalid && (userMobileForm.get('mobile_number')?.dirty || userMobileForm.get('mobile_number')?.touched)"
        class="text-danger pt-2">Please enter a valid mobile number</div>
    </div>
    <div *ngIf="showEmailField" [formGroup]="userEmailForm">
      <label class="mb-2"> Please enter your registered email id</label>
      <input type="text" class="form-control text-start" placeholder="Email ID" aria-label="Search"
        aria-describedby="basic-addon2" formControlName="email">
      <div
        *ngIf="userEmailForm.get('email')?.invalid && (userEmailForm.get('email')?.dirty || userEmailForm.get('email')?.touched)"
        class="text-danger pt-2">Please enter a valid Email id</div>
      <div class="mt-2 text-end">
        <span style="color:#245069;font-weight:500;text-decoration: underline;cursor: pointer;"
          (click)="goback()" *ngIf="country_id==undefined">Change Country</span>
      </div>
    </div>
    <div *ngIf="showNumberField" class="d-flex justify-content-center align-items-center mt-4">
      <button class="btn btn-primary" [disabled]="userMobileForm.invalid" (click)="getOTP('number')">Get OTP</button>
    </div>

    <div *ngIf="showEmailField" class="d-flex justify-content-center align-items-center mt-4">
      <button class="btn btn-primary" [disabled]="userEmailForm.invalid" (click)="getOTP('email')">Get OTP</button>
    </div>
  </div>

  <div *ngIf="steps=='step3'">
    <div id="work_area" class="d-flex flex-column justify-content-between rounded-top rounded-top-5">
      <div *ngIf="showNumberField">
        <div class="mt-4">
          <p>Verification code has been sent to
            {{userMobileForm.value.mobile_number}} <a (click)="changeStep()"
              style="color:#245069;font-weight:500;text-decoration: underline;cursor: pointer;">Edit</a></p>
        </div>
        <div class="otp-container d-flex flex-column">
          <div>
            <div *ngFor="let index of [0, 1, 2, 3]; let i = index" class="otp-input">
              <input type="text" maxlength="1" inputmode="tel" (keyup)="onKeyUp($event, i)" [(ngModel)]="otp[i]"
                [ngClass]="{ 'invalid': otpInvalid && !otp[i] }" />
            </div>
          </div>
          <p *ngIf="otpInvalid" class="error-message">Please enter a valid OTP.</p>
        </div>
        <div class="text-center pt-3">
          <p>Didn't recieve the code ?</p>
          <div class="d-flex justify-content-evenly">
            <a class="resend-text" (click)="resend('number')" [class.disabled]="disabled">Resend</a>
            <div class="d-flex">
              <div>{{ displayTime}}</div>s
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showEmailField">
        <div class="mt-4 text-center">
          <p>Verification code has been sent to
            {{userEmailForm.value.email}} <a (click)="changeStep()"
              style="color:#245069;font-weight:500;text-decoration: underline;cursor: pointer;">Edit</a></p>
        </div>
        <div class="otp-container d-flex flex-column">
          <div>
            <div *ngFor="let index of [0, 1, 2, 3, 4, 5]; let i = index" class="otp-input">
              <input type="text" maxlength="1" inputmode="tel" (keyup)="onKeyUp($event, i)" [(ngModel)]="otp[i]"
                [ngClass]="{ 'invalid': otpInvalid && !otp[i] }" />
            </div>
          </div>
          <p *ngIf="otpInvalid" class="error-message">Please enter a valid OTP.</p>
        </div>
        <div class="text-center pt-3">
          <p>Didn't recieve the code ?</p>
          <div class="d-flex justify-content-evenly">
            <a class="resend-text" *ngIf="showNumberField" (click)="resend('number')"
              [class.disabled]="disabled">Resend</a>
            <a class="resend-text" *ngIf="showEmailField" (click)="resend('email')"
              [class.disabled]="disabled">Resend</a>
            <div class="d-flex">
              <div>{{ displayTime}}</div>s
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showNumberField" class="d-flex justify-content-center align-items-center mt-4">
        <button class="btn btn-primary" (click)="verifyOTP('number')" [ngClass]="(otpInvalid) ? 'disable-verify':''"
          [disabled]="otpInvalid">Verify</button>
      </div>
      <div *ngIf="showEmailField" class="d-flex justify-content-center align-items-center mt-4">
        <button class="btn btn-primary" (click)="verifyOTP('email')" [ngClass]="(otpInvalid) ? 'disable-verify':''"
          [disabled]="otpInvalid">Verify</button>
      </div>
    </div>
  </div>
  <!-- </mat-card> -->
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#64d6e2" type="square-jelly-box" [fullScreen]="false">
  <p style="color: white"> Loading... </p>
</ngx-spinner>

<!-- <div class="d-flex align-items-center" style="background: #4F585B;height:100px">



  <div class="d-flex col-4 flex-column g-2 ps-xl-5">
    <div class="d-flex">
      <div class="d-flex">
        <img _ngcontent-vvw-c220="" src="/assets/amshuhu-logo.svg" style="width: 162px; height: 82px;">
      </div>
      <div class="d-flex flex-column justify-content-center">
        <div class="d-flex">
          <p class="ps-xl-4 pe-4" style="color: #FFFFFF;cursor:pointer;">Privacy Policy</p>
          <p class="ps-xl-4 " style="color: #FFFFFF;cursor:pointer;">Terms & Conditions</p>
        </div>
        <a href="mailto:sales@amshuhu.com" class="ps-xl-4 support-mail-text" style="color: #fff;">sales@amshuhu.com</a>
      </div>
    </div>
  </div>

</div> -->
