<div>
  <mat-toolbar class="tool-bar-color shadow" id="dialogToolbar">
    <div class="row g-0 w-100">
      <div class="col-4 d-flex align-items-center">
      </div>
      <div class="col-4 d-flex align-items-center justify-content-center">
        <p class="m-0 fs-6 text-white">Select / Capture Image</p>
      </div>
      <div class="col-4 d-flex align-items-center justify-content-end">
        <mat-icon style="color: #FFF;cursor: pointer;" [mat-dialog-close]>close</mat-icon>

      </div>
    </div>
  </mat-toolbar>

  <div id="dialogContent">
    <section class="d-flex justify-content-center align-items-center gap-5 h-100" *ngIf="!showCamera">
      <div class="d-flex flex-column align-items-center gap-2">
        <button class="btn rounded-circle"
          style="background-color: var(--primary-app-tool-bar);height: 100px;width: 100px;" (click)="openCam()"><img
            src="assets/mechanic-app/image/camera-white.svg" style="height: 35px;" alt=""></button>
        <p class="font-large" style="color: var(--primary-app-tool-bar);font-weight: 600;">Camera</p>
      </div>
      <div class="d-flex flex-column align-items-center gap-2">
        <button class="btn rounded-circle"
          style="background-color: var(--primary-app-tool-bar);height: 100px;width: 100px;" (click)="fileInput.click()">
          <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event)">
          <img src="assets/mechanic-app/image/gallery-white.svg" style="height: 35px;" alt=""></button>
        <p class="font-large" style="color: var(--primary-app-tool-bar);font-weight: 600;">Gallery</p>
      </div>
    </section>
    <section *ngIf="showCamera">
      <div class="d-flex flex-column justify-content-start align-items-center gap-1" id="cameraView">
        <video #video width="320" height="240" autoplay></video>
        <mat-divider></mat-divider>
        <canvas #canvas width="320" height="240"></canvas>
      </div>
      <div class="d-flex justify-content-center align-items-center gap-3" id="button">
        <button class="btn text-white" style="background-color: var(--primary-app-tool-bar);"
          (click)="captureImage()">Capture Image</button>
        <button class="btn text-white" style="background-color: var(--primary-app-tool-bar);"
          (click)="closeDialog()">Submit Image</button>
      </div>
    </section>
  </div>
</div>
