<div class="h-100">
  <div class="row g-0">
    <div class="col p-0"></div>
    <div class="col-12 col-xxl-10 p-0 h-100" id="mainDiv">
      <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer mode="over">
          <app-leftside-nav></app-leftside-nav>
        </mat-drawer>
        <mat-drawer-content>
          <mat-toolbar class="tool-bar-color shadow" id="mainToolBar" *ngIf="showMainToolbar">
            <div class="row g-0 w-100">
              <div class="col-4 d-flex align-items-center">
                <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
                  (click)="toggleSideNav()">
                  <mat-icon style="color:#FFF;">menu</mat-icon>
                </button>
                <img style="height: 50px;" class="ms-2" src="assets/mechanic-app/image/mvaahna_logo_round.svg">
              </div>
              <div class="col-4 d-flex align-items-center justify-content-center">
                <p class="m-0 text-white">{{userData.shop_name | base64Decode}}</p>
              </div>
              <div class="col-4 d-flex align-items-center justify-content-end">
                <button class="btn" (click)="goToNotification()">
                  <mat-icon style="color: #FFF;">notifications_none</mat-icon>
                </button>

              </div>
            </div>
          </mat-toolbar>


          <div class="work-area" id="workArea">
            <div id="topLayout" *ngIf="showToolbarNav">
              <section class="d-flex justify-content-center p-3 pb-2">
                <div class="d-flex justify-content-evenly align-items-center add-credit-section-bg w-75 p-2 ">
                  <img src="assets/mechanic-app/image/credit.svg" alt="">
                  <p class="m-0 fs-5 fw-bold text-white text-center">Available Credits</p>
                  <p class="m-0 fs-5 fw-bold text-white">{{jcCreditData?.job_card_count}}</p>
                </div>
                <div>
                  <button
                    class="d-flex justify-content-center align-items-center btn p-2 text-white fs-sm-5 fs-6 fw-bold rounded-0 rounded-end-2"
                    style="background: var(--primar-btn-1);height: 50px;" (click)="addCredit()"><mat-icon
                      class="mat-18">add</mat-icon>
                    <p class="m-0">Add Credits</p>
                  </button>
                </div>
              </section>
              <section class="d-flex justify-content-center p-3 pt-2">
                <div class="d-flex justify-content-evenly align-items-center w-75">
                  <input type="text" class="form-control search-box" placeholder="Search" [(ngModel)]="searchTerm"
                    (input)="onSearchChange()">
                </div>
                <div class="d-flex">
                  <button
                    class="d-flex justify-content-center align-items-center btn ms-3 text-white fs-sm-5 fs-6 fw-bold rounded-pill "
                    style="background:#FFF;" [matMenuTriggerFor]="sortMenu">
                    <mat-icon class="mat-18" style="color: var(--primary-app-tool-bar);">sort</mat-icon>
                    <p class="m-0" style="color: var(--primary-app-tool-bar);">{{sortMethod || "Sort by"}}</p>
                  </button>

                  <mat-menu #sortMenu="matMenu">
                    <button mat-menu-item (click)="setSortOption('A->Z')">A -> Z</button>
                    <button mat-menu-item (click)="setSortOption('Z->A')">Z -> A</button>
                    <button mat-menu-item (click)="setSortOption('Newest')">New date first</button>
                    <button mat-menu-item (click)="setSortOption('Oldest')">Oldest date first</button>

                  </mat-menu>
                </div>
              </section>

              <section>
                <div class="row g-0">
                  <div class="col-4">
                    <div class="d-flex justify-content-center align-items-center text-white section"
                      style="background: var(--primary-add-btn)" routerLink="create-customer">
                      <img src="assets/mechanic-app/image/addvehicle.svg" alt="">
                      <mat-icon class="mat-18">add</mat-icon>
                      <p class="m-0 fs-6">Add Vehicle</p>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="d-flex flex-column justify-content-center align-items-center section"
                      routerLink="all-vehicle" routerLinkActive="add-credit-section-bg">
                      <img src="assets/mechanic-app/image/allvehicles.svg" alt="">
                      <p class="m-0 fs-6 mt-1" routerLinkActive="text-white">All Vehicles</p>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="d-flex flex-column justify-content-center align-items-center section"
                      routerLink="vaahna-vehicle" routerLinkActive="add-credit-section-bg">
                      <img src="assets/mechanic-app/image/jobsinq.svg" alt="">
                      <p class="m-0 fs-6 mt-1" routerLinkActive="text-white">Vehicles</p>
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <div class="row g-0">
                  <ng-container *ngFor="let x of subRoutes">
                    <div class="col-3" [routerLink]="x.route">
                      <div class="d-flex flex-column justify-content-center align-items-center sub-nav-style"
                        routerLinkActive="add-credit-section-bg">
                        <img src="{{x.icon}}" alt="">
                        <p class="m-0 fs-6 mt-1" style="color: var(--primary-add-btn)" routerLinkActive="text-white">
                          {{x.label}}</p>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </section>

            </div>

            <div class="bg-light rounded-top" id="routingArea">
              <router-outlet></router-outlet>
            </div>


            <nav class="navbar navbar-expand-lg fixed-bottom"
              style="box-shadow: 0px -1px 7px 0px #0000001A;background: #FFFFFF;" id="bottomNavBar"
              *ngIf="showBottomBarNav">
              <div class="col-12">
                <div class="row justify-content-evenly">
                  <ng-container *ngFor="let x of nav_list">
                    <div class="col-3 text-center">
                      <a class="nav-link" [routerLink]="x.route">
                        <img [src]="x.icon" [ngClass]="'fill-none'">
                        <p class="m-0 route-txt text-capitalize fw-bold" routerLinkActive="active">
                          {{x.label}}</p>
                      </a>
                    </div>
                  </ng-container>
                </div>
              </div>
            </nav>


          </div>
        </mat-drawer-content>
      </mat-drawer-container>
      <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#64d6e2" type="square-jelly-box"
        [fullScreen]="false">
        <p style="color: white"> Loading... </p>
      </ngx-spinner>
    </div>
    <div class="col p-0"></div>
  </div>
</div>
