import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-circular-progress',
  templateUrl: './circular-progress.component.html',
  styleUrls: ['./circular-progress.component.scss']
})
export class CircularProgressComponent implements OnInit {
  @Input() value: number = 0;

  constructor() { }

  ngOnInit(): void {
  }

  get strokeDashOffset() {
    const radius = 50;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (this.value / 100) * circumference;
    return offset;
  }
}
