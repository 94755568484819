<div>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#64d6e2" type="square-jelly-box" [fullScreen]="false">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div class="position-relative">

    <!-- <google-map height="400px" width="750px" [center]="{lat:lat,lng:lng}" [zoom]="zoom" *ngIf="show">
  </google-map> -->
    <google-map [width]="dialogWidth" [height]="dialogHeight" [center]="center" [zoom]="zoom"
      (mapClick)="addMarker($event)" (mapMousemove)="move($event)">
      <map-marker *ngFor="let markerPosition of markerPositions" [position]="markerPosition"
        [options]="markerOptions"></map-marker>
    </google-map>

    <div id="shopAddress">
      <!-- <div class="d-flex  flex-md-row flex-column">
      <p class="d-flex align-items-center p-0 m-0 f-sm-4 fw-bold">Select Range&nbsp;:&nbsp;</p>
      <mat-slider class="example-margin" [max]="max" [min]="min" [step]="step" [thumbLabel]="true" [value]="value"
        (change)="changeRange($event)">
      </mat-slider>
    </div> -->
      <div class="d-flex flex-md-row flex-column mt-2" style="color: grey;">
        <p class="p-0 m-0 fs-sm-4 fw-bold">Current Set Address&nbsp;:&nbsp;</p>
        <p class="p-0 m-0 w-100 custom-address-size" style="cursor: not-allowed;">{{address}}</p>
      </div>
      <div class="d-flex justify-content-between flex-sm-row flex-column mt-2">
        <input type="text" class="custom-search-box border-0 border-bottom border-1 w-75"
          placeholder="Type in address to set location" [(ngModel)]="cur_addr">
        <div>
          <button class="btn book-btn" (click)="selectAddress()"><span>Set Location</span></button>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-2">
        <button class="btn book-btn" (click)="closeDialog('search')">Submit</button>
      </div>
    </div>
    <!-- Your dialog content -->
    <div class="position-absolute top-0 end-0" style="z-index: 1;">
      <button type="button" class="btn btn-outline-secondary" (click)="closeDialog('')">x
        <!-- <span class="bi bi-x">x</span> -->
      </button>
    </div>
  </div>
</div>
