// import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Component, OnInit, Input, Inject, ElementRef, HostBinding } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DeleteUserService } from '../delete-user.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, takeWhile, interval } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
// import { routeAnimationState, slideAnimation } from 'src/app/shared/animations/route-animation';
// import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { Observable, interval } from 'rxjs';
// import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss'],
})
export class DeleteUserComponent implements OnInit {



  constructor(private formBuilder: FormBuilder, private authService: DeleteUserService, private Toaster: ToastrService, public dialog: MatDialog, private el: ElementRef, private userService: UserService, private route: ActivatedRoute, private loader: NgxSpinnerService) {
    this.userMobileForm = this.formBuilder.group({
      mobile_number: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')])
    });
    this.userEmailForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required])
    });
  }

  userMobileForm: FormGroup;
  userEmailForm: FormGroup;
  selectedCountry: any
  selectedCountryObj: any
  countries: any;
  showNumberField: boolean = false
  showEmailField: boolean = false
  steps: string = 'step1';
  otp: string[] = [];
  otpInvalid: boolean = false;
  disabled: boolean = true;
  displayTime!: number;
  timer$!: Observable<number>;
  startTime = 10;

  country_id = undefined

  ngOnInit(): void {
    this.getAllCountries()
  }

  ngAfterViewInit() {
    const height = window.innerHeight - 156
    if (this.el.nativeElement.querySelector('#work_area') != null) {
      this.el.nativeElement.querySelector('#work_area').style.height = height + 'px'
    }
  }

  getAllCountries() {
    this.userService.getAllCountries().subscribe((res: any) => {
      if (res.status == 1) {
        this.countries = res.data
        this.route.queryParams.subscribe(params => {
          if (params['country_id']) {
            this.country_id = params['country_id']
            if (params['country_id'] == 1) {
              this.selectedCountryObj = {
                "id": 1, "name": "India", "country_code": "IN", "code": "+91", "unit_charge": "99", "no_of_credits": "99", "std_two_wheeler": "2", "std_four_wheeler": "5", "two_wheeler": "1", "four_wheeler": "2", "currency": "INR"
              }
              this.onSelect(this.selectedCountryObj)
            } else {
              let country_id = params['country_id']
              let countryDetails = this.countries.find((x: any) => x.id == country_id)
              this.onSelect(countryDetails)
            }
          }
        });
      }
    })
  }
  onSelect(x: any) {
    this.selectedCountryObj = x
    this.selectedCountry = x.name;

    if (x.name == 'India') {
      this.otp = ['', '', '', '']
      this.showNumberField = true
      this.showEmailField = false
    } else {
      this.otp = ['', '', '', '', '', '']
      this.showNumberField = false
      this.showEmailField = true
      // this.selectedCountry = ''
    }
    this.steps = 'step2'
  }

  changeStep() {
    this.steps = 'step2'
  }

  goback() {
    this.selectedCountry = ''
    this.selectedCountryObj = ''
    this.showNumberField = false
    this.showEmailField = false
    this.steps = 'step1'
  }

  getOTP(flag: string) {
    this.loader.show();
    if (flag == 'number') {
      this.authService.checkMechanic({ mobile_number: this.selectedCountryObj.code + this.userMobileForm.value.mobile_number }).subscribe((res: any) => {
        if (res.status == 1) {
          this.loader.hide();
          if (res.data[0].user == 0) {
            let data = this.openDialog();
          } else {
            this.authService.getOTP({ mobile_number: this.selectedCountryObj.code + this.userMobileForm.value.mobile_number }).subscribe((res: any) => {
              if (res.status == 1) {
                this.steps = 'step3'
                this.timer$ = interval(1000);
                this.startTimer()
                this.Toaster.success('OTP sent Successfully', '', {
                  timeOut: 3000
                })
                this.loader.hide();
              }
            })
          }
        }
      })
    } else {
      this.authService.checkMechanic({ email: this.userEmailForm.value.email, country: this.selectedCountry }).subscribe((res: any) => {
        if (res.status == 1) {
          this.loader.hide();
          if (res.data[0].user == 0) {
            let data = this.openDialog();
          } else {
            this.authService.deleteUserOTP({ email: this.userEmailForm.value.email, country: this.selectedCountry }).subscribe((res: any) => {
              if (res.status == 1) {
                this.steps = 'step3'
                this.timer$ = interval(1000);
                this.startTimer()
                this.Toaster.success('OTP sent Successfully', '', {
                  timeOut: 3000
                })
                this.loader.hide();
              } else if (res.status == 2) {
                this.loader.hide();
                this.Toaster.warning('User is In-Active..!', '', {
                  timeOut: 3000
                })
              }
            })
          }
        }
      })
    }
  }

  resend(flag: string) {
    this.loader.show();

    if (flag == 'number') {

      if (!this.disabled) {
        this.authService.getOTP({ mobile_number: this.selectedCountryObj.code + this.userMobileForm.value.mobile_number }).subscribe((res: any) => {
          if (res.status == 1) {
            this.disabled = true
            this.displayTime = 10
            this.startTime = 10
            this.timer$ = interval(1000);
            this.startTimer()
            this.Toaster.success('OTP resent Successfully', '', {
              timeOut: 3000
            })
            this.loader.hide();
          }
        })
      }
    } else {
      this.authService.deleteUserOTP({ email: this.userEmailForm.value.email, country: this.selectedCountry }).subscribe((res: any) => {
        if (res.status == 1) {
          this.disabled = true
          this.displayTime = 10
          this.startTime = 10
          this.timer$ = interval(1000);
          this.startTimer()
          this.Toaster.success('OTP sent Successfully', '', {
            timeOut: 3000
          })
          this.loader.hide();
        } else if (res.status == 2) {
          this.loader.hide();
          this.Toaster.warning('User is In-Active..!', '', {
            timeOut: 3000
          })
        }
      })

    }
  }

  startTimer() {
    // this.timer$ = interval(1000);
    this.timer$
      .pipe(takeWhile(() => this.displayTime !== 0))
      .subscribe(() => {
        this.displayTime = this.startTime--;
        if (this.displayTime === 0) {
          this.disabled = false

        }
      });
  }


  onKeyUp(event: any, index: number) {
    const inputValue = event.target.value;
    const otp_box = (this.selectedCountry == 'India') ? 3 : 5
    if (inputValue.length === 1 && index < otp_box) {
      const nextElement = event.target.parentElement?.nextElementSibling?.querySelector('input');
      if (nextElement) {
        nextElement.focus();
      }
    } else if (inputValue.length === 0 && index > 0) {
      const prevElement = event.target.parentElement?.previousElementSibling?.querySelector('input');
      if (prevElement) {
        prevElement.value = '';
        prevElement.focus();
      }
    }
    this.validateOTP();
  }


  validateOTP() {
    this.otpInvalid = this.otp.some(digit => digit === '');
  }

  verifyOTP(flag: string) {

    this.dialog.open(DialogAnimationsExampleDialog1, {
      width: '500px',
    }).afterClosed().subscribe((res: any) => {

      if (res) {
        this.loader.show();
        const otp = parseInt(this.otp.join('')).toString();
        let input1: any;
        let input2: any;
        if (flag == 'number') {
          input1 = { ...{ mobile_number: this.selectedCountryObj.code + this.userMobileForm.value.mobile_number }, ...{ otp: otp } }
          input2 = { ...{ mobile_number: this.selectedCountryObj.code + this.userMobileForm.value.mobile_number }, ...{ status: 'DEL' } }
        } else {
          input1 = { ...this.userEmailForm.value, ...{ otp: otp, country: this.selectedCountry } }
        }

        this.authService.verify_otp(input1).subscribe((res: any) => {
          if (res.status == 1) {

            if (flag == 'email') {
              input2 = { mobile_number: res.data[0].phone.toString(), status: 'DEL', country: this.selectedCountry, email: this.userEmailForm.value.email }
            }

            this.authService.changeStatus(input2).subscribe((res1: any) => {
              if (res1.status == 1) {
                // this.steps = 'step1'
                this.onSelect(this.selectedCountryObj)
                this.selectedCountry = ''
                this.userMobileForm.controls['mobile_number'].setValue('')
                this.userEmailForm.controls['email'].setValue('')
                this.otp = ['', '', '', '']
                this.Toaster.success('Deleted Successfully', '', {
                  timeOut: 3000
                })
                this.loader.hide();
              }
            })
          } else {
            this.loader.hide();
            this.Toaster.error('Invalid OTP', '', {
              timeOut: 3000
            })
          }
        })
      }
    });
  }

  openDialog(): void {
    this.dialog.open(DialogAnimationsExampleDialog, {
      width: '500px',
    }).afterClosed().subscribe((res: any) => {
    });
  }

}


@Component({
  selector: 'dialog-animations-example-dialog',
  template: `
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="warningDialogLabel" style="color: #235B9D;">Alert</h2>
        <!-- <button type="button" class="btn-close"(click)='closeDialog()'></button> -->
      </div>
      <div class="modal-body mt-2 mb-2 fs-6" >
        <p>The entered mobile number is not registered with us, please verify the mobile number</p>
      </div>
      <div class="modal-footer gap-2">
        <button type="button" class="btn btn-secondary" (click)="closeDialog(false)">Close</button>
        <!-- <button type="button" class="btn btn-primary" (click)="closeDialog(true)">Continue</button> -->
      </div>
    </div>
  </div>
  `,
})
export class DialogAnimationsExampleDialog {
  constructor(public dialogRef: MatDialogRef<DialogAnimationsExampleDialog>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  closeDialog(flag: boolean) {
    this.dialogRef.close(flag);

  }
}


@Component({
  selector: 'dialog-animations-example-dialog1',
  template: `
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="warningDialogLabel" style="color: #235B9D;">Alert</h2>
        <!-- <button type="button" class="btn-close"(click)='closeDialog()'></button> -->
      </div>
      <div class="modal-body mt-2 mb-2 fs-6">
        <p>If you Delete your account, your entire data will be deleted. Are you sure you want to Delete your account</p>
      </div>
      <div class="modal-footer gap-2">
        <button type="button" class="btn btn-secondary" (click)="closeDialog(false)">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="closeDialog(true)">Continue</button>
      </div>
    </div>
  </div>
  `,
})
export class DialogAnimationsExampleDialog1 {
  constructor(public dialogRef: MatDialogRef<DialogAnimationsExampleDialog>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  closeDialog(flag: boolean) {
    this.dialogRef.close(flag);

  }
}
