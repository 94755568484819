import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LanguageSelectComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) { }

  languageList = [{ name: "English", value: "English" }, { name: "Tamil (தமிழ்)", value: "Tamil" }, { name: "Malayalam (Malayalam)", value: "Malayalam" }, { name: "Kannada (ಕನ್ನಡ)", value: "Kannada" }, { name: "Hindi (हिंदी)", value: "Hindi" }, { name: "Telugu (తెలుగు)", value: "Telugu" }, { name: "Marathi (मराठी)", value: "Marathi" }, { name: "Gujarati (ગુજરાતી)", value: "Gujarati" }]

  ngOnInit(): void {
    console.log(this.data);

  }

  getSupportNumber(lang: String) {
    switch (lang) {
      case "English":
      case "Tamil":
        return "+917305798882";

      case "Telugu":
      case "Kannada":
        return "+918925807260";

      case "Hindi":
        return "+917305798881";

      case "Malayalam":
        return "+917305798882";

      case "Gujarati":
        return "+919429504827";

      case "Marathi":
        return "73581 44403";

      default:
        return "+917305798882";
    }
  }

  sendMessage(lan: string) {
    if(this.data.type=='chat'){
    let phone = this.getSupportNumber(lan)
    const url = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent('hi')}`;
    window.open(url, '_blank');
    }else{
      this.closeDialog()
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
