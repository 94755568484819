<div id="mainDiv" class="h-100">
  <div class="d-flex justify-content-between align-items-center ps-sm-5 ps-1"
    style="height: 100px;background: linear-gradient(180deg, #E8F6F7 8.85%, #AFE4E7 27.35%, #2C63A1 110.83%);">

    <mat-icon svgIcon="mvaahna" style="width: 200px;height: 80px;"></mat-icon>
    <!-- <div class="col-md-6 d-flex align-items-center justify-content-end pe-sm-5 pe-1 gap-1">
      <div class="text-decoration-underline d-flex align-items-center" (click)="closeWindow()"
        style="cursor: pointer;position: relative;top: 1.5px;">
        Go Back Home
      </div>
      <div>
        Logged in as user <span [matMenuTriggerFor]="menu"
          style="color:#2C63A1;text-decoration: underline;cursor: pointer;" class="fs-5 fw-bold">{{firstLetter}}</span>
      </div>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="logout()"><mat-icon>exit_to_app</mat-icon>Logout</button>
      </mat-menu>
    </div> -->
  </div>
  <!-- <div class="d-flex justify-content-center align-items-center" style="height: 60px;">
    <div>
      <button class="btn btn-primary" (click)="showcatalog()"> <mat-icon class="mat-18"
          style="vertical-align: bottom;">add</mat-icon> Add Credit</button>
    </div>
  </div> -->

  <div id="catalogueArea" class="bg-light">
    <div class="col d-flex flex-lg-row flex-column g-0">
      <div class="col-lg-4">
        <div class="container mt-5">
          <h2>Choose a Package</h2>
          <div class="card-deck mt-4">
            <mat-radio-group [(ngModel)]="selectedPackage" (ngModelChange)="changePackage(selectedPackage)">
              <ng-container *ngFor="let x of catalogueList">
                <div class="card mt-2">
                  <div class="card-body">
                    <mat-radio-button [value]="x.amount" class="w-100">
                      <div class="ps-3">
                        <h5 class="card-title">{{x.currency_symbol|base64Decode}}{{x.amount}} package</h5>
                        <p class="card-text">{{x.package_name}}</p>
                        <p class="card-text " style="font-weight: 550;">{{x.credits}} Credits</p>
                      </div>
                    </mat-radio-button>
                  </div>
                </div>
              </ng-container>
            </mat-radio-group>
          </div>

          <!-- <div class="mt-3">
            <button class="btn btn-primary" (click)="purchase()" [disabled]="selectedPackage==0">Purchase</button>
          </div> -->
        </div>
      </div>
      <div class="col-lg-4 mt-lg-5 pt-2">
        <div class="container mt-5 border rounded-1 bg-white p-0">
          <div class="border-bottom">
            <div class="p-3">
              <div class="d-flex align-items-center mb-3 gap-2">
                <h2 class="m-0">Shopping Cart </h2>
                <mat-icon>shopping_cart</mat-icon>
              </div>
              <div>
                <div class="row">
                  <div class="col-6 text-start">
                    <p>Sub Total</p>
                  </div>
                  <div class="col-6 text-end">
                    <p class="fs-6">{{selectedPackageObj?.currency_symbol|base64Decode}}{{selectedPackageObj?.amount}}</p>
                  </div>
                  <div class="col-6 text-start">
                    <p>Tax</p>
                  </div>
                  <div class="col-6 text-end">
                    <p class="fs-6" *ngIf="selectedPackageObj?.amount">{{tax}}</p>
                  </div>

                  <div class="col-6 text-start mt-2">
                    <p class="fs-6 fw-bold">Order Total</p>
                  </div>
                  <div class="col-6 text-end">
                    <p class="fs-6 fw-bold">{{selectedPackageObj?.currency_symbol|base64Decode}}{{selectedPackageObj?.amount}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container mt-lg-3 mb-5">
            <div class="row border-bottom ms-2 me-2 pb-2">
              <div class="col-4 text-start">ITEM</div>
              <div class="col-4 text-center">QTY</div>
              <div class="col-4 text-center">PRICE</div>
            </div>
            <div class=" row mt-3">
              <div class="col-4 text-center">{{ selectedPackageObj?.package_name }}</div>
              <div class="col-4 text-center" *ngIf="selectedPackageObj?.package_name">1</div>
              <div class="col-4 text-center">{{selectedPackageObj?.currency_symbol|base64Decode}}{{selectedPackageObj?.amount}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 mt-lg-5 p-2">
        <div class=" border mt-5 rounded-1 bg-white container" *ngIf="countryId!=1">
          <div class="d-flex align-items-center pt-3">
            <h2 class="m-0">Payment Method </h2>
          </div>
          <div>
            <div id="paypal-button-container" #paypalBtn class="paypal-button-container mt-3 bg-transparent p-0"></div>
          </div>
          <div class="line-container mb-3">
            <hr>
            <p class="fs-6">or</p>
            <hr>
          </div>
          <div id="checkout-form" class="mb-3">
            <div id="card-name-field-container" #cardFieldArea1></div>
            <div id="card-number-field-container" #cardFieldArea2></div>
            <div id="card-expiry-field-container" #cardFieldArea3></div>
            <div id="card-cvv-field-container" #cardFieldArea4></div>
            <button id="multi-card-field-button" type="button" #multiCardFieldButton>Pay now with Credit or Debit
              Card</button>
          </div>
          <div style="font-size: 12px;" class="text-center mb-3">
            By clicking Pay now you agree to the <span
              style="text-decoration: underline;cursor: pointer;color: darkgreen;font-weight: 550;"
              (click)="openTAndC()">Terms & Conditions</span>
          </div>
        </div>
        <div class=" border mt-5 rounded-1 bg-white container" *ngIf="countryId==1">
          <div class="d-flex align-items-center pt-3">
            <h2 class="m-0">Payment Method </h2>
          </div>
          <div class="text-center">
            <img [src]="qrCodeUrl" alt="UPI QR Code">
          </div>
          <div class="text-center">
            Time left: <span class="text-primary">{{ formatTime(timeLeft) }}</span>
          </div>
          <div class="text-center pt-2 pb-2" *ngIf="isMobile">
            <span class="fs-6">Scan the above QR code or click the button below to complete the payment with your
              desired upi app</span>
            <div class="mt-3">
              <a [href]="upiPaymentLink" target="_blank" class="text-decoration-none">
                <button class="upi-pay-button">Pay with UPI</button>
              </a>
            </div>
          </div>
          <div class="text-center pt-2 pb-2" *ngIf="!isMobile">
            <span class="fs-6">Scan the above QR code to complete the payment with your desired upi app</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
