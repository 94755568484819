<div>
  <div class="d-flex justify-content-between align-items-center tool-bar-color shadow p-3 gap-2" id="fixed-top-bar">
    <p class="m-0 fs-5 text-white text-center">Choose Your Brand</p>
    <button class="btn">
      <mat-icon class="mat-18 text-white" [mat-dialog-close]>close</mat-icon>
    </button>
  </div>
  <div class="input-group m-2" style="width: 96%;">
    <span class="input-group-text">
      <mat-icon class="mat-18">search</mat-icon>
    </span>
    <input type="text" [(ngModel)]="searchText" class="form-control custom-input border-start-0" placeholder="Search"
      aria-label="Search" aria-describedby="basic-addon2">
  </div>
  <div id="scrollableArea" class="bg-white">
    <div>
      <div class="row g-0" *ngIf="userData.user_category=='both'">
        <div class="col-6">
          <div class="d-flex justify-content-center align-items-center section"
            style="margin: 10px 25px;border: 1px solid #ccc;" (click)="changeCategory('two wheeler')"
            [ngStyle]="{'border-color': (vehicleCatrgory=='two wheeler') ? 'var(--text-color-1)': '#ccc'}">
            <p class="m-0 fs-6" [ngClass]="(vehicleCatrgory=='two wheeler')?'section-active':''">Two Wheeler</p>
          </div>
        </div>
        <div class="col-6">
          <div class="d-flex flex-column justify-content-center align-items-center section"
            style="margin: 10px 25px;border: 1px solid #ccc;" (click)="changeCategory('four wheeler')"
            [ngStyle]="{'border-color': (vehicleCatrgory=='four wheeler') ? 'var(--text-color-1)': '#ccc'}">
            <p class="m-0 fs-6" [ngClass]="(vehicleCatrgory=='four wheeler')?'section-active':''">Four Wheeler</p>
          </div>
        </div>
      </div>
    </div>
    <mat-tab-group animationDuration="1000ms" *ngIf="showtabs">
      <mat-tab label="Brand">
        <div class="container" id="modelContainer">
          <div class="row">
            <div class="col-4 d-flex justify-content-center align-items-center"
              *ngFor="let x of brandDetails | globalFilter:['brand']: searchText">
              <div class="brand-details" (click)="changeModel(x.brand);resizeDialog()">
                <img [src]="x.brand_image" class="brand-image" alt="Brand Image" *ngIf="x.brand_image">
                <div class="brand-info pt-3">
                  <h5 class="fw-bold text-center">{{ x.brand }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Model">
        <div class="container" id="modelContainer1">
          <div class="row">
            <div class="col-4 d-flex justify-content-center align-items-center"
              *ngFor="let x of modelDetails | globalFilter:['model']: searchText">
              <div class="brand-details" (click)="changeFuel(x.brand, x.model);resizeDialog()">
                <img [src]="x.model_image" class="brand-image" alt="Brand Image" *ngIf="x.model_image">
                <div class="brand-info pt-3">
                  <h5 class="fw-bold text-center">{{ x.model }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Fuel">
        <div class="container" id="modelContainer2">
          <div class="row">
            <div class="col-4 d-flex justify-content-center align-items-center"
              *ngFor="let x of fuelDetails | globalFilter:['shop_name']: searchText">
              <div class="brand-details" (click)="setVehicle(x)">
                <img src="assets/mechanic-app/image/petrol.svg" class="brand-image" alt="Brand Image">
                <div class="brand-info pt-3">
                  <h5 class="fw-bold text-center">{{ x.fuel_type }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
