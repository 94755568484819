import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-count-down',
  template: `<div>{{ displayTime }}</div>`,
  styleUrls: ['./count-down.component.scss']
})
export class CountDownComponent implements OnInit {

  @Input() startTime = 60;
  @Output() countdownFinished = new EventEmitter<void>();

  displayTime!: number;
  timer$!: Observable<number>;

  ngOnInit() {
    this.timer$ = interval(1000);
    this.startTimer();
  }

  // startTimer() {
  //   this.timer$
  //     .pipe(takeWhile(() => this.displayTime !== 0))
  //     .subscribe(() => {
  //       this.displayTime = this.startTime--;
  //     });
  // }

  startTimer() {
    this.timer$
      .pipe(takeWhile(() => this.displayTime !== 0))
      .subscribe(() => {
        this.displayTime = this.startTime--;
        if (this.displayTime === 0) {
          this.countdownFinished.emit();
        }
      });
  }

}
