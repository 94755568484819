import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { from, map, Observable } from 'rxjs';
import axios, { AxiosInstance } from 'axios'

@Injectable({
  providedIn: 'root'
})
export class UpiService {
  axiosInstance: AxiosInstance;
  userData: any
  constructor() {
    const userdata = sessionStorage.getItem('userData')
    if (userdata) {
      this.userData = JSON.parse(userdata)
    }

    this.axiosInstance = axios.create({
      baseURL: 'https://mconnect.isteer.co/mconnect/',
      headers: {
        'Content-Type': 'application/json',
        // 'app_platform': 'mvaahna',
        // 'user_id': this.userData.id.toString(),
        // 'company_id': this.userData.company_id.toString(),
        // 'country_id': this.userData.country_id.toString()
      }
    });
  }

  public paymentStart(input: any): Observable<any> {
    return from(this.axiosInstance.post('paymentStart', input).then(
      res => {
        return res.data
      }
    ))
  }
  public checkPaymentStatus(input: any): Observable<any> {
    const additional_headers = {
      "user_id": input.user_id,
      "company_id": input.company_id
    }
    const headers = { ...this.axiosInstance.defaults.headers.common, ...additional_headers };

    return from(this.axiosInstance.post('checkPaymentStatus', input, { headers }).then(
      res => {
        return res.data
      }
    ))
  }
}
