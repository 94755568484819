import { MechanicComponentModule } from './mechanic-app/mechanic-component.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckoutGuardGuard } from './shared/guard/checkout-guard.guard';
import { MobileAppRelatedComponent } from './mobile-app-related/mobile-app-related.component';
import { PagesComponent } from './pages/pages.component';
import { MechanicAppComponent } from './mechanic-app/mechanic-app.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { SubMechanicComponent } from './sub-mechanic/sub-mechanic.component';
import { RegisterMechanicComponent } from './register-mechanic/register-mechanic.component';

const routes: Routes = [
  {
    path: "mvaahna",
    component: PagesComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: "mvaahna-mobile",
    component: MobileAppRelatedComponent,
    loadChildren: () => import('./mobile-app-related/mobile-app-related.module').then(m => m.MobileAppRelatedModule)
  },
  {
    path: 'mconnect',
    loadChildren: () => import('./delet-user-module/delete-user.module').then(m => m.DeleteUserModule),
  },
  {
    path: 'mvaahna-mechanic',
    component: MechanicAppComponent,
    loadChildren: () => import('./mechanic-app/mechanic-component.module').then(m => m.MechanicComponentModule),
  },
  {
    path: 'mechanic-registration/:number',
    component: RegisterMechanicComponent,
    loadChildren: () => import('./register-mechanic/register-mechanic.module').then(m => m.RegisterMechanicModule),
  },
  {
    path: 'sub-mechanic',
    component: SubMechanicComponent,
    loadChildren: () => import('./sub-mechanic/sub-mechanic.module').then(m => m.SubMechanicModule),
  },
  {
    path: "",
    redirectTo: "mvaahna",
    pathMatch: "full",

  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
