import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomService } from '../../services/custom.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-add-dialog',
  templateUrl: './add-dialog.component.html',
  styleUrls: ['./add-dialog.component.scss']
})
export class AddDialogComponent implements OnInit {

  userForm!: FormGroup;
  jsonData: any;
  apiUrl!: string;

  constructor(private dialogRef: MatDialogRef<AddDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private _customService: CustomService, private toastr: ToastrService) { }

  ngOnInit(): void {
    console.log(this.data.data);

    this.jsonData = this.data.inputJson;
    this.apiUrl = this.data.apiUrl
    this.buildForm();
  }


  buildForm(): void {
    const formGroupConfig: any = {};

    this.jsonData.fields.forEach((field: any) => {
      if (field.type === 'input') {
        formGroupConfig[field.name] = ['', field.validation];
      } else if (field.type === 'select') {
        formGroupConfig[field.name] = ['', field.validation];
      }
    });

    this.userForm = this.fb.group(formGroupConfig);
    if (this.data.title == "Edit Service Details") {
      let advance_paid
      try {
        advance_paid = parseInt(JSON.parse(this.data.data.advance_payment).total)
      } catch (error) {
        advance_paid = 0
      }

      this.userForm.controls['vehiclereceivedfrom'].setValue(Buffer.from(this.data.data.vehicle_received_from, 'base64').toString('utf8'))
      this.userForm.controls['vehiclecollectedby'].setValue(Buffer.from(this.data.data.vehicle_collected_by, 'base64').toString('utf8'))
      this.userForm.controls['advance_paid'].setValue(advance_paid)
      this.userForm.controls['pickup_kilometer'].setValue(this.data.data.pickup_Kilometer)
      this.userForm.controls['issues'].setValue(Buffer.from(this.data.data.issues, 'base64').toString('utf8'))
      this.userForm.controls['remarks'].setValue(Buffer.from(this.data.data.remarks, 'base64').toString('utf8'))

    } else {
      this.userForm.patchValue(this.data.data)
    }
  }

  addData() {

    if (this.data.title == "Edit Service Details") {
      let advanceAmount = (this.userForm.value.advance_paid != '' || this.userForm.value.advance_paid != 0) ? { data: [{ amount: this.userForm.value.advance_paid, date: moment().format('YYYY-MM-DD HH:mm:ss') }], total: this.userForm.value.advance_paid } : { total: 0 }
      const input = {
        vehiclereceivedfrom: Buffer.from(this.userForm.value.vehiclereceivedfrom, 'utf8').toString('base64'),
        vehiclecollectedby: Buffer.from(this.userForm.value.vehiclecollectedby, 'utf8').toString('base64'),
        advance_paid: JSON.stringify(advanceAmount),
        pickup_kilometer: this.userForm.value.pickup_kilometer,
        issues: Buffer.from(this.userForm.value.issues, 'utf8').toString('base64'),
        remarks: Buffer.from(this.userForm.value.remarks, 'utf8').toString('base64'),
        jobcard_id: parseInt(this.data.data.jobcard_id),
        track_id: 1,
        mechanic_id: this.data.data.mechanic_id
      }
      this._customService.addDataToDB('updateMconnectJobCardStatus', input).subscribe((res: any) => {
        if (res.status == 1) {
          this.toastr.success("Jobcard  Edited Successfully..!", "", {
            timeOut: 3000
          })
          this.dialogRef.close()
        }
      })

    } else {

      if (this.data.data.spare_id != undefined) {
        this.dialogRef.close({ ...this.userForm.value, ...{ spare_id: this.data.data.spare_id } })
      } else {
        this.dialogRef.close(this.userForm.value)
      }
    }

  }

}
