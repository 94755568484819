import { UpiService } from './../services/upi.service';
import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
// import { UserService } from '../home/services/user.service';
// import { PaypalService } from '../home/services/paypal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../shared/services/user.service';
import { PaypalService } from './../services/paypal.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription, interval, takeWhile } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PaymentSuccessDialogComponent } from '../dialog/payment-success-dialog/payment-success-dialog.component';
window.Buffer = window.Buffer || require('buffer').Buffer;
declare var paypal: any;


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {


  @ViewChild('paypalBtn') paypalBtn!: ElementRef
  @ViewChild('cardFieldArea1') cardFieldArea1!: ElementRef
  @ViewChild('cardFieldArea2') cardFieldArea2!: ElementRef
  @ViewChild('cardFieldArea3') cardFieldArea3!: ElementRef
  @ViewChild('cardFieldArea4') cardFieldArea4!: ElementRef
  userData: any
  showCatalog: boolean = false
  showPayment: boolean = false

  catalogueList: any[] = []

  catalogueMaster = [
    { countryName: "India", countryId: 1, data: [{ package: '₹1', label: 'Basic Package', value: 1, credits: 1 }, { package: '₹99', label: 'Basic Package', value: 99, credits: 99 }, { package: '₹200', label: 'Standard Package', value: 200, credits: 220 }, { package: '₹500', label: 'Premium Package', value: 500, credits: 550 }] },
    { countryName: "UAE", countryId: 7, data: [{ package: '$0.99', label: 'Sample Package', value: 0.99, credits: 10 }, { package: '$4.99', label: 'Basic Package', value: 4.99, credits: 100 }, { package: '$14.99', label: 'Standard Package', value: 14.99, credits: 150 }, { package: '$24.99', label: 'Premium Package', value: 24.99, credits: 250 }] },
  ]

  selectedPackage: any;
  selectedPackageObj!: any;
  firstLetter!: string;
  paymentStatus!: string;
  orderId: any;
  countryId: any;
  userId: any;
  tax!: string
  upiPaymentLink!: any;
  qrCodeUrl!: string
  isMobile!: boolean
  merchantTranId: any;
  timeLeft: number = 300;
  private timerSubscription!: Subscription;
  constructor(private el: ElementRef, private toaster: ToastrService, private userService: UserService, private paypalService: PaypalService, private Router: Router, private renderer: Renderer2, private loader: NgxSpinnerService, public dialog: MatDialog, private route: ActivatedRoute, private UpiService: UpiService, private readonly sanitizer: DomSanitizer,) {

    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    this.route.queryParams.subscribe(params => {
      this.countryId = Buffer.from(params['nyx'], 'base64').toString('utf-8');
      this.userId = Buffer.from(params['nyz'], 'base64').toString('utf-8');

      // const catalogueList = this.catalogueMaster.filter((x: any) => {
      //   var flag = (this.countryId == 1) ? 1 : 7
      //   if (x.countryId == flag) {
      //     return x
      //   }
      // }).map((y: any) => y.data)
      // this.catalogueList = catalogueList[0]

      this.fetchMechanicData()
      this.fetchCatalog()
    });

    this.renderer.listen('window', 'resize', (event) => {
      this.resizePage()
    })

  }

  ngOnInit(): void {
    this.selectedPackage = (this.countryId == 1) ? 99 : 0.99
    this.tax = (this.countryId != 1) ? '$0' : '₹0'
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.changePackage(this.selectedPackage)
    }, 100);
    this.resizePage()
  }


  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  startTimer() {
    this.timerSubscription = interval(1000)
      .pipe(
        takeWhile(() => this.timeLeft > 0)
      )
      .subscribe(() => {
        this.timeLeft--;
        if (this.timeLeft % 10 === 0) {
          this.UpiService.checkPaymentStatus({ merchantTranId: this.merchantTranId, user_id: this.userData.id.toString(), company_id: this.userData.company_id.toString(), country_id: this.userData.country_id.toString() }).subscribe((res: any) => {
            if (res.status == 1) {
              this.timeLeft = 0
              if (res.mgs.status.toLowerCase() == 'success') {

                this.dialog.open(PaymentSuccessDialogComponent, {
                  width: '700px',
                  data: res.mgs
                }).afterClosed().subscribe((res: any) => {
                });

              }
            }

          }
          );
        }
      });
  }

  formatTime(time: number): string {
    const minutes: number = Math.floor(time / 60);
    const seconds: number = time % 60;
    return `${this.padWithZero(minutes)}:${this.padWithZero(seconds)}`;
  }

  padWithZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  fetchMechanicData() {
    const input = { user_id: this.userId }
    this.userService.fetchMechanicData(input).subscribe((res: any) => {
      if (res.status == 1) {
        this.firstLetter = Buffer.from(res.data[0].name, 'base64').toString('utf-8').toUpperCase();
        this.userData = res.data[0]
      }
    })
  }

  fetchCatalog() {
    const input = { country_id: this.countryId }
    this.userService.fetchCatalogList(input).subscribe((res: any) => {
      if (res.status == 1) {
        this.catalogueList = res.data
      }
    })
  }

  showcatalog() {
    this.showCatalog = !this.showCatalog
    this.resizePage()

  }

  resizePage() {
    setTimeout(() => {
      const height = this.el.nativeElement.querySelector('#mainDiv').offsetHeight;
      const resized_height = height - 100
      if (this.el.nativeElement.querySelector('#catalogueArea')) {
        this.el.nativeElement.querySelector('#catalogueArea').style.height = resized_height + 'px'
        this.el.nativeElement.querySelector('#catalogueArea').style.overflow = 'scroll'
      }
    }, 1);
  }

  sanitizeUrl(url: any) {
    return this.sanitizer.bypassSecurityTrustUrl(url)
  }

  changePackage(input: number) {
    console.log(this.catalogueList);

    let obj = this.catalogueList.filter((x: any) => {
      if (x.amount == input) {
        return x
      }
    })

    this.selectedPackageObj = obj[0]
    console.log(this.selectedPackageObj);

    if (this.countryId != 1) {
      if (this.paypalBtn) {
        this.paypalBtn.nativeElement.innerHTML = '';
        this.cardFieldArea1.nativeElement.innerHTML = '';
        this.cardFieldArea2.nativeElement.innerHTML = '';
        this.cardFieldArea3.nativeElement.innerHTML = '';
        this.cardFieldArea4.nativeElement.innerHTML = '';
      }
      this.initiatePayPal('#paypal-button-container', this.selectedPackage)
      this.initiateButton()
    } else {
      this.UpiService.paymentStart({ amount: this.selectedPackage }).subscribe((res: any) => {
        this.merchantTranId = res.mgs.merchantTranId
        // const url = this.sanitizeUrl((this.isMobile) ? "upi://pay?pa=amshuhu@icici&pn=amshuhu&tr=EZV2024020615411968448114&am=99&cu=INR&mc=5411" : `upi://pay?pa=amshuhu@icici&pn=amshuhu&tr=${res.mgs.refId}&am=${this.selectedPackage}&cu=INR&mc=5411`)
        // const url=`upi://pay?pa=amshuhu@icici&pn=amshuhu&tr=${res.mgs.refId}&am=${this.selectedPackage}&cu=INR&mc=5411`
        const url = (this.isMobile) ? this.sanitizeUrl(`upi://pay?pa=ISTEERMCONNECT@icici&pn=AMS&tr=${res.mgs.refId}&am=${this.selectedPackage}&cu=INR&mc=5411`) : `upi://pay?pa=ISTEERMCONNECT@icici&pn=AMS&tr=${res.mgs.refId}&am=${this.selectedPackage}&cu=INR&mc=5411`

        if (url) {
          this.upiPaymentLink = url
          if (this.timeLeft < 300) {
            this.timeLeft = 300
          }
          this.startTimer();
        }

        this.qrCodeUrl = 'https://chart.googleapis.com/chart?cht=qr&chl=' + encodeURIComponent(this.upiPaymentLink) + '&chs=200x200';
      })

    }
  }





  updatePayalPayment() {

    let input = { order_id: '', transaction_id: this.orderId, status: 'success', amount: this.selectedPackage.toString(), company_id: this.userData.company_id, user_id: this.userData.id, country_id: this.userData.country_id, credit: this.selectedPackageObj.credits }
    this.userService.updatePaypalPayment(input).subscribe((res: any) => {
      if (res.status == 1) {
        // this.selectedPackage = '';
        // this.selectedPackageObj = ''
        this.toaster.success('Payment Completed Successfully', '', {
          timeOut: 3000
        });
        this.openDialog()

        this.loader.hide();
        // const dataToSend = { message: 'Payment Done Succesfully' };
        // window.opener.postMessage({ type: 'popupData', payload: dataToSend }, '*');

        // this.selectedPackage = '';
        // this.selectedPackageObj = '';
        // setTimeout(() => {
        //   window.close();
        // }, 3000);
      }
    })
  }

  async openDialog() {
    console.log({ Amount: this.selectedPackage, merchantTranId: this.orderId, status: 'Payment Completed..!' });

    await this.dialog.open(PaymentSuccessDialogComponent, {
      width: '700px',
      data: { Amount: this.selectedPackage, merchantTranId: this.orderId, status: 'Payment Completed..!' },
    }).afterClosed().subscribe((res: any) => {
    });
  }

  logout() {
    sessionStorage.clear();
    this.Router.navigate(['/home']);
    this.toaster.success('Logged Out Successfully', '', {
      timeOut: 3000
    })
  }




  /*----------------Currently in user--------------------*/
  @ViewChild('multiCardFieldButton') multiCardFieldButton!: ElementRef;

  initiatePayPal(containerId: any, amount: any) {
    console.log(amount);

    paypal.Buttons({
      style: {
        layout: 'vertical',
        color: 'gold', //orange, expected gold, blue, silver, black, white
        shape: 'rect', //rect,pill,
      },
      createOrder: (data: any, actions: any) => {
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: amount
            }
          }]
        });
      },
      onApprove: async (data: any, actions: any) => {
        const order = await actions.order.capture();
        this.orderId = order.id
        // this.paypalBtn.nativeElement.innerHTML = '';
        // this.cardFieldArea1.nativeElement.innerHTML = '';
        // this.cardFieldArea2.nativeElement.innerHTML = '';
        // this.cardFieldArea3.nativeElement.innerHTML = '';
        // this.cardFieldArea4.nativeElement.innerHTML = '';
        this.loader.show();
        this.updatePayalPayment();
        // console.log('Capture result:', order);
        return order
      },
      onError: (err: any) => {
        this.toaster.error("Please select a package and proceed for payement", "", {
          timeOut: 3000
        })
        // console.error('PayPal error:', err);
      }
    }).render(containerId);
  }

  initiateButton() {
    const styleObject = {
      input: {
        "font-size": "16px",
        "font-family": "monospace",
        "font-weight": "lighter",
        color: "black",
      },
      ".invalid": {
        color: "red",
      },
      ":hover": {
        color: "blue",
      },
      ".purple": {
        color: "purple",
      },
    };

    const cardField = paypal.CardFields({
      style: styleObject,
      createOrder: (data: any, actions: any) => {
        return fetch(environment.BaseUrl + "createPayPalOrder", {
          method: "post",
          body: JSON.stringify({ amount: this.selectedPackage }),
          headers: {
            'Content-Type': 'application/json'
          }

        })
          .then((res) => {
            return res.json();
          })
          .then((orderData) => {
            this.orderId = orderData.data.id
            return orderData.data.id;
          });
      },
      onApprove: (data: any, actions: any) => {
        const { orderID } = data;
        return fetch(environment.BaseUrl + `capturePayPalOrder`, {
          method: "post",
          body: JSON.stringify({ orderId: orderID }),
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((res) => {
            return res.json();
          })
          .then((orderData) => {
            // Redirect to success page
          });
      },
      inputEvents: {
        onChange: (data: any) => {
          // Handle a change event in any of the fields
        },
        onFocus: (data: any) => {
          // Handle a focus event in any of the fields
        },
        onBlur: (data: any) => {
          // Handle a blur event in any of the fields
        },
        onInputSubmitRequest: (data: any) => {
          console.log(data);

          // Handle an attempt to submit the entire card form
          // while focusing any of the fields
        }
      },
    });

    const cardNameContainer = document.getElementById("card-name-field-container");
    const cardNumberContainer = document.getElementById("card-number-field-container");
    const cardCvvContainer = document.getElementById("card-cvv-field-container");
    const cardExpiryContainer = document.getElementById("card-expiry-field-container");

    if (cardField.isEligible()) {
      const nameField = cardField.NameField();
      nameField.render(cardNameContainer);
      const numberField = cardField.NumberField();
      numberField.render(cardNumberContainer);
      const cvvField = cardField.CVVField();
      cvvField.render(cardCvvContainer);
      const expiryField = cardField.ExpiryField();
      expiryField.render(cardExpiryContainer);


      this.multiCardFieldButton.nativeElement.addEventListener("click", () => {
        this.loader.show();
        cardField
          .submit()
          .then(() => {
            // Handle a successful payment
            console.log("payment successful ...!");

            this.paymentStatus = 'Payment successful';
            this.paypalBtn.nativeElement.innerHTML = '';
            this.cardFieldArea1.nativeElement.innerHTML = '';
            this.cardFieldArea2.nativeElement.innerHTML = '';
            this.cardFieldArea3.nativeElement.innerHTML = '';
            this.cardFieldArea4.nativeElement.innerHTML = '';
            setTimeout(() => {
              this.updatePayalPayment();
            }, 1000);
          })
          .catch((err: any) => {
            // Handle an unsuccessful payment
            // console.error('Payment error:', err);
            this.paymentStatus = 'Payment failed';
          });
      });
    }
  }

  openTAndC(): void {
    this.dialog.open(TermsAndConditionDialogBox, {
      width: '700px',
    }).afterClosed().subscribe((res: any) => {
      console.log(res);
    });
  }
}


@Component({
  selector: 'terms-and-condition-dialog-box',
  template: `
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="warningDialogLabel" style="color: #235B9D;">Terms And Conditions</h2>
      </div>
      <div class="modal-body mt-2 mb-2 fs-6">
        <ul>
          <li>By paying with your card, you acknowledge that your data will be processed by PayPal subject to the PayPal Privacy Statement available at PayPal.com.</li>
          <li class="pt-2">We use PayPal for payments and other services. If you wish to use one of these services and pay on our website, PayPal may collect the personal data you provide, such as payment and other identifying information. PayPal uses this information to operate and improve the services it provides to us and others, including for fraud detection, harm and loss prevention, authentication, analytics related to the performance of its services, and to comply with applicable legal requirements. The processing of this information will be subject to the PayPal Privacy Statement available at PayPal.com.</li>
        </ul>
      </div>
      <div class="modal-footer gap-2">
        <button type="button" class="btn btn-secondary" (click)="closeDialog(false)">close</button>
      </div>
    </div>
  </div>
  `,
})
export class TermsAndConditionDialogBox {
  constructor(public dialogRef: MatDialogRef<TermsAndConditionDialogBox>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  closeDialog(flag: boolean) {
    this.dialogRef.close(flag);

  }
}
