import { Component} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mvaahna_com';
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon('mvaahna', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/mvaahna-logo.svg'));
    this.matIconRegistry.addSvgIcon('close-jc', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/close-jc.svg'));
    this.matIconRegistry.addSvgIcon('create-cust', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/create-cust.svg'));
    this.matIconRegistry.addSvgIcon('create-jc', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/create-jc.svg'));
    this.matIconRegistry.addSvgIcon('download-img', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/download-img.svg'));
    this.matIconRegistry.addSvgIcon('invoice', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/invoice.svg'));
    this.matIconRegistry.addSvgIcon('set-up', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/set-up.svg'));
    this.matIconRegistry.addSvgIcon('amshuhu', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/amshuhu-banner-img.svg'));
    this.matIconRegistry.addSvgIcon('isteer', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/isteer.svg'));
    this.matIconRegistry.addSvgIcon('contact-us-support', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/contact-us-support-img.svg'));
    this.matIconRegistry.addSvgIcon('contact-us-inq', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/contact-us-inq-img.svg'));
    this.matIconRegistry.addSvgIcon('wp-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/wp-icon.svg'));
    this.matIconRegistry.addSvgIcon('email-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/email-icon.svg'));
    this.matIconRegistry.addSvgIcon('mv-login', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/mv-login-logo.svg'));
    this.matIconRegistry.addSvgIcon('language-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/language-icon.svg'));
  }



}
