import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../../dialog-box/login/login.component';
import { ToastrService } from 'ngx-toastr';
import { AuthGuard } from '../../guard/auth.guard';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  // navItem = [{ label: "Home", route: "home" }, { label: "About Us", route: "about-us" }, { label: "FAQ", route: "faq" }, { label: "Contact Us", route: "contact-us" }, { label: "Add Credit", route: () => this.openCredit() }, { label: "Login", route: () => this.openLogin('login', 1) }]
  navItem = [{ label: "Home", route: "mvaahna/home" }, { label: "About Us", route: "mvaahna/about-us" }, { label: "FAQ", route: "mvaahna/faq" }, { label: "Contact Us", route: "mvaahna/contact-us" }, { label: "Login", route: 'mvaahna/login' }]
  login: boolean = false;
  firstLetter!: string;

  userData: any
  showToolbar: any = true;
  private subscription: Subscription;
  constructor(private dialogbox: MatDialog, private toaster: ToastrService, private authService: AuthService, private router: Router, private globalService: GlobalService) {
    this.subscription = this.globalService.headerData$.subscribe(data => {
      if (data) {
        this.showToolbar = (data.stb) ? false : true;
      }
    });
  }

  ngOnInit(): void {
    this.checkLogin()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  checkLogin() {
    this.login = (this.authService.isLoggedIn()) ? true : false
    if (this.login) {
      const userdata = sessionStorage.getItem('userData')
      if (userdata) {
        this.userData = JSON.parse(userdata)
        this.firstLetter = atob(JSON.parse(userdata).name).toUpperCase();
      }
      this.navItem[4].label = 'Add Credit';
      this.navItem[4].route = 'credit';
    } else {
      this.navItem[4].label = 'Login';
      this.navItem[4].route = 'login';
    }
  }

  // getRoute(route: string | Function): string | null {
  //   // console.log(route);

  //   return typeof route === 'function' ? null : route;
  // }

  // handleNavigation(route: string | Function): void {
  //   if (typeof route === 'function') {
  //     // Handle login route
  //     this.openLogin('login', 1);
  //   }
  // }

  navigateToPage(route: any) {
    // console.log(route);

    if (route == 'login') {
      this.openLogin('login', 1);
    } else if (route == 'credit') {
      this.openCredit()
    } else {
      const nav = '/' + route
      this.router.navigate([nav])
    }
  }

  openLogin(flag: String, step: number) {
    if (flag == 'login' && !this.login) {
      let width;
      let height;

      if (window.innerWidth > 992) {
        width = '30%'
        height = '80%'
      } else if (window.innerWidth > 768 && window.innerWidth < 992) {
        width = '50%'
        height = '80%'
      } else {
        width = '80%'
        height = '80%'
      }

      this.dialogbox.open(LoginComponent, {
        width: width,
        height: height,
        panelClass: 'zero-padding-margin-dialog-login-comp',
        data: { step: step }
        // disableClose: true,
      }).afterClosed().subscribe((result: any) => {
        if (result == 'guest') {
          // this.browseAsGuest();
        } else {

          const userdata = sessionStorage.getItem('userData')
          if (userdata) {
            this.firstLetter = atob(JSON.parse(userdata).name).toUpperCase();
          }
        }
        this.checkLogin()
      })
    } else {
      sessionStorage.clear();
      this.checkLogin();
      this.toaster.success('Logged Out Successfully', '', {
        timeOut: 3000
      })
    }
  }

  popupWindow: any;

  openCredit(): void {
    this.popupWindow = window.open(`https://checkout.mvaahna.com/#/checkout?nyx=${Buffer.from(this.userData.country_id.toString(), 'utf-8').toString('base64')}&nyz=${Buffer.from(this.userData.id.toString(), 'utf-8').toString('base64')}`, '_blank');
  }

}
