import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private searchTermSource = new BehaviorSubject<string>('');
  currentSearchTerm = this.searchTermSource.asObservable();

  private sortSource = new BehaviorSubject<string>('');
  sort = this.sortSource.asObservable();

  setSearchTerm(term: string) {
    this.searchTermSource.next(term);
  }

  setSort(method: any) {
    this.sortSource.next(method)
  }
}
