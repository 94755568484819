<div class="p-3 border-bottom border-white ms-2 me-3">
  <div class="row g-0">
    <div class="col-1"></div>
    <div class="col-10 d-flex align-items-center justify-content-center"> <label
        class="font-large fw-bold text-white text-center ">Add Vehicle</label></div>
    <div class="col-1 d-flex align-items-center justify-content-center">
      <mat-icon class="text-white" (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
    </div>
  </div>
</div>

<div class="d-flex flex-column mt-2" [formGroup]="VehicleForm">
  <div class="ps-3 pe-3">
    <div class="d-flex flex-column mb-2">
      <div class="input-group">
        <span class="input-group-text bg-white" id="basic-addon1" style="height: 58px;">
          <img style="height: 25px;width: 25px;" src="assets/mechanic-app/image/both_brand.svg" alt="">
        </span>
        <div class="form-floating">
          <input type="email" class="form-control border-start-0" id="floatingInput" formControlName="vehicle_brand"
            placeholder="Vehicle Brand">
          <label for="floatingInput">Vehicle Brand</label>
        </div>
      </div>
      <div>
        <mat-error
          *ngIf="VehicleForm.get('vehicle_brand')?.hasError('required') && VehicleForm.get('vehicle_brand')?.touched">This
          field is required</mat-error>
      </div>
    </div>

    <div class="d-flex flex-column mb-2">
      <div class="input-group">
        <span class="input-group-text bg-white" id="basic-addon1" style="height: 58px;">
          <img style="height: 25px;width: 25px;" src="assets/mechanic-app/image/both_model.svg" alt="">
        </span>
        <div class="form-floating">
          <input type="email" class="form-control border-start-0" id="floatingInput" formControlName="vehicle_model"
            placeholder="Vehicle Brand">
          <label for="floatingInput">Vehicle Model</label>
        </div>
      </div>
      <div>
        <mat-error
          *ngIf="VehicleForm.get('vehicle_model')?.hasError('required') && VehicleForm.get('vehicle_model')?.touched">This
          field is required</mat-error>
      </div>
    </div>

    <div class="d-flex flex-column mb-2">
      <div class="input-group">
        <span class="input-group-text bg-white" id="basic-addon1" style="height: 58px;">
          <img style="height: 25px;width: 25px;" src="assets/mechanic-app/image/fueltype.svg" alt="">
        </span>
        <div class="form-floating">
          <input type="email" class="form-control border-start-0" id="floatingInput" formControlName="fuel_type"
            placeholder="Vehicle Brand">
          <label for="floatingInput">Fuel Type</label>
        </div>
      </div>
      <div>
        <mat-error
          *ngIf="VehicleForm.get('fuel_type')?.hasError('required') && VehicleForm.get('fuel_type')?.touched">This
          field is required</mat-error>
      </div>
    </div>
  </div>

</div>

<div class="p-3 ms-2 me-3">
  <div class="d-flex justify-content-center align-items-center ">
    <button class="btn text-white border-white ps-3 pe-3 rounded-2" (click)="otherVehicle()">Done</button>
  </div>
</div>
